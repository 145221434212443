import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { ClickableDiv } from '../../atoms';
import './ProviderLocationsSelector.less';

type Location = {
  id: number;
  city?: string;
  state?: string;
  zip?: string;
  addressLine1?: string;
  officeName?: string | null;
};

type InputProps = {
  pickedLocation: Location;
  otherLocations: Location[];
  onLocationChanged: (locationId: any) => void;
  staticLocation?: boolean;
};

function capitalizeFirstLetter(word: string) {
  if (word.length === 0) return '';
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
}

function formatOfficeName(officeName: string) {
  return officeName.toLowerCase().replace(/\w+/g, capitalizeFirstLetter);
}

class ProviderLocationSelector extends React.Component<
  InputProps,
  { hoveredLocationId: number | null }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      hoveredLocationId: null,
    };
  }

  renderLocation(location: Location, pickedLocation: Location) {
    const { hoveredLocationId } = this.state;
    return (
      <Dropdown.Item
        key={`location-${location.id}`}
        eventKey={`${location.id}`}
        style={{
          background: hoveredLocationId === location.id ? 'var(--secondary-color)' : 'white',
        }}
        onMouseOver={() => {
          this.setState({ hoveredLocationId: location.id });
        }}
        className={`${hoveredLocationId === location.id ? 'menu-item-active' : undefined} ${
          pickedLocation.id === location.id ? 'menu-item-selected' : undefined
        } `}
      >
        <div>
          <div className="item-title text-16 text-semibold">
            {location?.officeName && location.officeName !== 'None'
              ? formatOfficeName(location.officeName)
              : null}
          </div>
          <div className="text-14">
            {location.addressLine1}, {location.city} {location.state} {location.zip}
          </div>
        </div>
      </Dropdown.Item>
    );
  }

  render() {
    const { otherLocations, pickedLocation, staticLocation, onLocationChanged } = this.props;
    const locationTitleToShow =
      pickedLocation.officeName && pickedLocation.officeName !== 'None'
        ? formatOfficeName(pickedLocation.officeName)
        : pickedLocation.addressLine1;
    if (otherLocations.length === 0) {
      return <div className="single-location">{locationTitleToShow}</div>;
    }
    if (staticLocation) {
      return <div>{locationTitleToShow}</div>;
    }
    return (
      <ClickableDiv clickHandler={(e) => e.stopPropagation()} tabIndex={-1}>
        <Dropdown
          id="summery"
          className="locations-dropdown pill-btn-dropdown margin-top-5"
          onSelect={(locationIdString) =>
            onLocationChanged(Number.parseInt(locationIdString as string, 10))
          }
        >
          <Dropdown.Toggle>{locationTitleToShow}</Dropdown.Toggle>
          <Dropdown.Menu>
            {otherLocations.map((location) => this.renderLocation(location, pickedLocation))}
          </Dropdown.Menu>
        </Dropdown>
      </ClickableDiv>
    );
  }
}

export default ProviderLocationSelector;
