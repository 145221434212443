import React, { FunctionComponent } from 'react';
import { BookButton, BookingType, Text, ColorNameEnum, SizeEnum, Button } from '@getvim/atomic-ui';
import { LocationType } from '../../../models/Location';

const SelectedProviderActions: FunctionComponent<{
  npi: string;
  fullProviderName: string;
  location: LocationType;
  memberToken?: string;
  onBook: (payload: { npi: string; address: string; memberToken?: string }) => void;
  onClose: () => void;
  onShareClick?: () => void;
  theme: any;
  displayBookButton: boolean;
  displayShareButton: boolean;
  displayCopyButton: boolean;
}> = ({
  npi,
  fullProviderName,
  location,
  memberToken,
  onBook,
  onClose,
  onShareClick,
  theme,
  displayShareButton,
  displayBookButton,
  displayCopyButton,
}) => {
  return (
    <>
      <div className="banner-text">
        <div>
          <Text size={SizeEnum['14px']} colorName={ColorNameEnum.ultraDark}>
            {displayCopyButton
              ? 'Provider’s details were copied to the clipboard'
              : 'Provider’s details were updated in your EHR'}
          </Text>
        </div>
      </div>
      <div className="banner-buttons">
        <Button buttonType="small" bgColor="themedOutline" width="small" onClick={onClose}>
          Close
        </Button>
        {memberToken && displayShareButton && (
          <Button buttonType="small" bgColor="themedOutline" width="small" onClick={onShareClick}>
            Share
          </Button>
        )}
        {!!location.bookingType && displayBookButton && (
          <BookButton
            bookingType={BookingType[location.bookingType]}
            onClick={() => onBook({ npi, address: location.address, memberToken })}
            buttonType="small"
            btnClassName="btn-min-100"
          />
        )}
      </div>
    </>
  );
};

export default SelectedProviderActions;
