import { string, type, TypeOf } from 'io-ts';

export const PhoneNumberTypeV = type(
  {
    number: string,
    countryDialingCode: string,
  },
  'PhoneNumberType',
);

export type PhoneNumberType = TypeOf<typeof PhoneNumberTypeV>;
