import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import isNil from 'lodash.isnil';
import partition from '@getvim/utils-partition';
import {
  deprecated,
  Availability,
  AvailabilitySizeEnum,
  Container,
  ContainerWidth,
  PoweredByVimFooter,
  Loader,
  AsoMetadata,
  ProviderLocations,
  ProfessionalBackground,
} from '@getvim/atomic-ui';
import useVerticalMode from '@getvim/components-hooks-use-vertical-mode';
import { ThemePropsType } from '@getvim/components-hooks-use-theme';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { AnalyticsInstance, SearchActionEvents } from '@getvim/components-utils-analytics';
import Config from '../../../config';
import { ProviderType } from '../../../models/Provider';
import { LocationType } from '../../../models/Location';
import { GetByNpiResponse } from '../../../api/responseTypes';
import authHandler from '../../../api/authHandler';
import * as Api from '../../../api/api';

import './ProviderDetailsModal.less';

const SMALL_SCREEN_WIDTH = 768;

export interface DoctorDetailsProps {
  provider: ProviderType;
  insurer?: string | null;
  asoMetadata?: AsoMetadata;
  theme: ThemePropsType;
  selectedLocation: LocationType;
  renderActions?: (provider: ProviderType, location: LocationType) => void;
  className?: string;
  analytics?: AnalyticsInstance;
}

const Badges: FunctionComponent<{
  location: LocationType;
}> = ({ location }) => {
  return (
    <Availability
      acceptNewPatients={location.acceptNewPatients}
      bookingHorizon={location.bookingHorizon}
      size={AvailabilitySizeEnum.large}
      showBg
    />
  );
};

const enrichProviderFields = (provider: ProviderType, enrichedFields: GetByNpiResponse) => {
  const { boardCertifications, medicalDegree, languages } = enrichedFields;
  const newProvider = {
    ...provider,
    boardCertifications,
    medicalDegree,
    languages,
  };

  newProvider.locations = newProvider.locations.map((location) => {
    const enrichedLocation = enrichedFields.locations.find(
      (currEnrichedLocation) => currEnrichedLocation.id === location.id,
    );
    if (enrichedLocation) {
      const { openHours } = enrichedLocation;
      return { ...location, openHours };
    }
    return location;
  });
  return newProvider;
};

const DoctorDetails: FunctionComponent<DoctorDetailsProps> = ({
  provider,
  asoMetadata,
  selectedLocation,
  theme,
  className,
  insurer,
  renderActions,
  analytics,
}) => {
  const [enrichedFieldsProvider, setEnrichedFieldsProvider] = useState<GetByNpiResponse>();
  const [fetchFailed, setFetchFailed] = useState(false);
  const [viewedLocation, setViewedLocation] = useState<number>(selectedLocation.id);
  const mergedProvider = useMemo(() => {
    return enrichedFieldsProvider
      ? enrichProviderFields(provider, enrichedFieldsProvider)
      : provider;
  }, [enrichedFieldsProvider, provider]);
  const [[pickedLocation], otherLocations] = useMemo(
    () => partition(mergedProvider.locations, (currLocation) => currLocation.id === viewedLocation),
    [viewedLocation, mergedProvider],
  );
  const isVerticalMode = useVerticalMode(SMALL_SCREEN_WIDTH);

  const [googleMapsEnabled, isGoogleMapsEnabledFlagLoading] = useFeatureFlag({
    namespace: 'search-sdk',
    flagName: 'googleMaps.Enabled',
    defaultValue: false,
  });

  const mapConfig = { mapConfig: Config.VIM_MAP_CONFIG };

  useEffect(() => {
    if (isGoogleMapsEnabledFlagLoading) return;

    if (googleMapsEnabled) mapConfig.mapConfig = { key: Config.VIM_MAPS_GOOGLE_KEY };
    const { eventName, params } = SearchActionEvents.mapsLoad({
      mapType: googleMapsEnabled ? 'google' : 'bing',
    });

    analytics?.track(eventName, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGoogleMapsEnabledFlagLoading, googleMapsEnabled]);

  useEffect(() => {
    setEnrichedFieldsProvider(undefined);
    setFetchFailed(false);
    Api.getProviderByNpi({ npi: provider.npi, insurer })
      .then((res) => {
        setEnrichedFieldsProvider(res);
      })
      .catch((error) => {
        authHandler(error);
        setFetchFailed(true);
      });
  }, [provider.npi, insurer]);

  const showMap = () => {
    const { eventName, params } = SearchActionEvents.mapsLoad({
      mapType: googleMapsEnabled ? 'google' : 'bing',
    });

    analytics?.track(eventName, params);
  };

  if (!enrichedFieldsProvider && !fetchFailed) return <Loader />;

  return (
    <div className={classNames('doctor-details-wrap', className)}>
      <deprecated.MiniProviderCard
        provider={{ ...mergedProvider }}
        asoMetadata={asoMetadata}
        location={pickedLocation}
        className="dr-details-top-card"
        renderActions={renderActions}
        showShadow
      />

      <Container width={ContainerWidth.large}>
        <div className="details-body margin-top-50">
          <div className="row">
            <div className="col-sm-6">
              {isVerticalMode && <Badges location={pickedLocation} />}
              <ProviderLocations
                mapConfig={mapConfig}
                location={pickedLocation}
                otherLocations={otherLocations}
                onLocationChanged={(locationId: number) => {
                  const newLocation = provider.locations.find(
                    (location) => location.id === locationId,
                  );
                  if (newLocation) setViewedLocation(newLocation.id);
                }}
                showMap={showMap}
              />
            </div>
            <div className="col-sm-6">
              {!isVerticalMode && <Badges location={pickedLocation} />}

              <ProfessionalBackground className="margin-top-10" details={mergedProvider} />
            </div>
          </div>
        </div>
      </Container>
      <PoweredByVimFooter hovering />
    </div>
  );
};

export default DoctorDetails;
