import React, { FunctionComponent } from 'react';

const SvgClinicalMatch: FunctionComponent = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    viewBox="0 0 32.06 36.65"
    width="72px"
    height="72px"
    className="svg-icon svg-cactus"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <path
          className="cls-1"
          d="M28.64,29.86a14.2,14.2,0,0,0-7.1-6.53,7.37,7.37,0,0,0,1.94-5,7.45,7.45,0,0,0-14.89,0,7.36,7.36,0,0,0,1.93,5,14.2,14.2,0,0,0-7.1,6.53.67.67,0,0,0,.07.73,16,16,0,0,0,25.08,0A.65.65,0,0,0,28.64,29.86ZM9.89,29.23a1,1,0,1,1-1,1A1,1,0,0,1,9.89,29.23ZM16,35.32A14.63,14.63,0,0,1,4.81,30.1a13.07,13.07,0,0,1,4.34-4.53V28A2.34,2.34,0,1,0,10.48,28V24.83A13.62,13.62,0,0,1,12,24.21a.64.64,0,0,0,.45-.51.68.68,0,0,0-.23-.64,6.05,6.05,0,0,1-2.26-4.7,6.11,6.11,0,0,1,12.22,0,6.05,6.05,0,0,1-2.26,4.7.7.7,0,0,0-.23.64.67.67,0,0,0,.45.51,12.15,12.15,0,0,1,1.4.58v2.08A2.23,2.23,0,0,0,19.94,29v2a1.16,1.16,0,0,0,1.15,1.15.67.67,0,0,0,.67-.67.68.68,0,0,0-.48-.64V29a.89.89,0,1,1,1.78,0v1.88a.67.67,0,0,0,.19,1.31A1.16,1.16,0,0,0,24.4,31V29a2.23,2.23,0,0,0-1.56-2.12V25.52a13.07,13.07,0,0,1,4.41,4.58A14.63,14.63,0,0,1,16,35.32Z"
          fill="var(--main-color)"
        />
        <path
          className="cls-2"
          d="M16.48.3l1.26,2.55,2.81.4a.54.54,0,0,1,.3.92l-2,2L19.29,9a.54.54,0,0,1-.78.57L16,8.2,13.49,9.52A.54.54,0,0,1,12.71,9l.48-2.8-2-2a.54.54,0,0,1,.3-.92l2.81-.4L15.52.3A.53.53,0,0,1,16.48.3Z"
          fill="var(--main-color)"
        />
        <path
          className="cls-2"
          d="M28,6.45l.83,1.7,1.88.27a.36.36,0,0,1,.2.62l-1.36,1.32.32,1.87a.36.36,0,0,1-.52.38l-1.68-.88L26,12.61a.36.36,0,0,1-.52-.38l.32-1.87L24.41,9a.37.37,0,0,1,.2-.62l1.88-.27.84-1.7A.36.36,0,0,1,28,6.45Z"
          fill="var(--main-color)"
        />
        <path
          className="cls-2"
          d="M4.67,6.45l.84,1.7,1.88.27a.37.37,0,0,1,.2.62L6.23,10.36l.32,1.87a.36.36,0,0,1-.52.38l-1.68-.88-1.68.88a.36.36,0,0,1-.52-.38l.32-1.87L1.11,9a.36.36,0,0,1,.2-.62l1.88-.27L4,6.45A.36.36,0,0,1,4.67,6.45Z"
          fill="var(--main-color)"
        />
        <path
          className="cls-3"
          d="M7.68,7a1.88,1.88,0,0,1,1.22.82,14.32,14.32,0,0,1,2.63-1.14l-1.06-1A16.37,16.37,0,0,0,7.68,7Z"
          fill="var(--secondary-color)"
        />
        <path
          className="cls-3"
          d="M24.36,7a15.93,15.93,0,0,0-2.82-1.36l-1.06,1a14.67,14.67,0,0,1,2.64,1.13A1.86,1.86,0,0,1,24.36,7Z"
          fill="var(--secondary-color)"
        />
        <path
          className="cls-3"
          d="M29.5,14.15a1.55,1.55,0,0,1-.31,0A14.58,14.58,0,0,1,29,27.54a.66.66,0,0,0,.27.9.72.72,0,0,0,.32.08.69.69,0,0,0,.59-.35,15.93,15.93,0,0,0,.36-14.34A1.87,1.87,0,0,1,29.5,14.15Z"
          fill="var(--secondary-color)"
        />
        <path
          className="cls-3"
          d="M1.34,20.62A14.59,14.59,0,0,1,2.88,14.1a1.58,1.58,0,0,1-.38.05,1.87,1.87,0,0,1-1-.29A15.8,15.8,0,0,0,0,20.62a16,16,0,0,0,1.9,7.55.67.67,0,0,0,.9.27.65.65,0,0,0,.27-.9A14.76,14.76,0,0,1,1.34,20.62Z"
          fill="var(--secondary-color)"
        />
      </g>
    </g>
  </svg>
);

export default SvgClinicalMatch;
