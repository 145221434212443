import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Text, ColorNameEnum, SizeEnum, deprecated } from '@getvim/atomic-ui';
import { useTheme } from '@getvim/components-hooks-use-theme';
import Formatter from '@getvim/components-utils-formatter';

export interface ShareResultPopoverProps {
  isLoading?: boolean;
  onSubmit: (phoneNumber: deprecated.PhoneNumberValue) => void;
  onClose: () => void;
  initialPhoneNumber?: string;
}

const ShareResultPopover: FunctionComponent<ShareResultPopoverProps> = ({
  isLoading = false,
  onSubmit,
  onClose,
  initialPhoneNumber,
}) => {
  const theme = useTheme();
  const [phoneNumber, setPhoneNumber] = useState<deprecated.PhoneNumberValue>({ number: '' });
  useEffect(() => {
    if (initialPhoneNumber) {
      setPhoneNumber(Formatter.stringToPhoneNumber(initialPhoneNumber.toString()));
    }
  }, [initialPhoneNumber, setPhoneNumber]);
  return (
    <div className="share-doctor">
      <div className="row margin-bottom-10">
        <div className="col-xs-12">
          <deprecated.PhoneNumberInput
            name="phoneNumber"
            initialValue={phoneNumber}
            className="phone-number-input no-margin"
            onChange={setPhoneNumber}
            autoFocus
          />
        </div>
      </div>
      <div className="margin-top-20 text-center">
        <Button width="small" buttonType="small" bgColor="themedOutline" onClick={onClose}>
          Close
        </Button>
        &nbsp;
        <Button
          width="small"
          buttonType="small"
          onClick={() => onSubmit(phoneNumber)}
          isLoading={isLoading}
        >
          Send
        </Button>
      </div>

      <Text
        className="disclaimer margin-top-10"
        size={SizeEnum['14px']}
        colorName={ColorNameEnum.ultraDark}
      >
        By clicking send you confirm that you obtained the patient’s consent to receive this text
        message
      </Text>
    </div>
  );
};

export default ShareResultPopover;
