import React, { FunctionComponent } from 'react';
import Text from '../text';
import { ColorNameEnum, SizeEnum } from '../../types';

import './PreferredProviderBadge.less';

const PreferredProviderBadge: FunctionComponent = () => (
  <div className="preferred-provider-badge">
    <Text
      colorName={ColorNameEnum.themeSecondary}
      size={SizeEnum['16px']}
      className="badge-icon icon-like"
    />
    <Text colorName={ColorNameEnum.ultraDark} size={SizeEnum['14px']} className="badge-text">
      Preferred provider
    </Text>
  </div>
);

export default PreferredProviderBadge;
