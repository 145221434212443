import React, { FunctionComponent } from 'react';
import { DefaultToastContainer, ToastContainerProps } from 'react-toast-notifications';
import './ToastNotifications.less';

const FrontToastContainer: FunctionComponent<ToastContainerProps> = ({ children, ...props }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <DefaultToastContainer {...props} className="react-toast-notifications__front">
    {children}
  </DefaultToastContainer>
);
export default FrontToastContainer;
