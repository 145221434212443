export default function genderConverter(
  gender: string | null | undefined,
  forMale: string,
  forFemale: string,
  forOther: string,
) {
  if (gender === 'MALE') return forMale;
  if (gender === 'FEMALE') return forFemale;
  return forOther;
}
