import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { AlignEnum, ColorNameEnum, SizeEnum, WeightEnum } from '../../types';
import { Button, Text, Popover, usePopoverState } from '../../atoms';
import { ButtonColor } from '../../atoms/button';

import './index.less';

export type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';

/* Using this regex with string.split will return an array of strings, where odd indexed strings are
   links. For example:
   "Hi. First link: http://hi.com?a=1 and second link: www.a.b/c Bye!".split(linkRegex)" will
   return: ["Hi. First link: ", "http://hi.com?a=1", " and second link: ", "www.a.b/c", " Bye!"] */
const linkRegex = /\b((?:http|www)\S*)\b/g;
const isLink = (index: number) => index % 2 === 1;
const addLinksToUrls = (description: string) => {
  const split = description.split(linkRegex);
  return split.map((str, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={index}>
      {isLink(index) ? (
        <a href={str} target="_blank" rel="noopener noreferrer">
          <Button buttonType="link">{str}</Button>
        </a>
      ) : (
        str
      )}
    </span>
  ));
};

type NetworkTierProps = {
  description: string;
  placement?: Placement;
  color?: ButtonColor;
  title?: string;
  className?: string;
  block?: boolean;
  paddingLeft?: boolean;
};

const NetworkTierDescription: FunctionComponent<NetworkTierProps> = ({
  description,
  placement,
  color,
  title = '',
  className = '',
  block = false,
  paddingLeft = false,
}) => {
  const descriptionPopover = usePopoverState({ placement });
  const isBlock = block ? 'block text-center' : undefined;
  const isPaddingLeft = paddingLeft ? 'padding-left' : undefined;
  return (
    <Popover
      popover={descriptionPopover}
      aria-label="Network Tier"
      className={classNames('network-popover', className)}
      disclosure={
        <Button color={color} buttonType="link" className={isBlock}>
          <Text
            size={SizeEnum['12px']}
            align={AlignEnum.left}
            className={classNames('network-tier-description', className, isPaddingLeft)}
            inline
          >
            {title}
          </Text>
        </Button>
      }
      withArrow
    >
      <div className="popover-content">
        <Text
          size={SizeEnum['12px']}
          align={AlignEnum.left}
          colorName={ColorNameEnum.dark}
          weight={WeightEnum.semibold}
        >
          {title}&nbsp;providers
        </Text>
        <Text size={SizeEnum['12px']} align={AlignEnum.left} className="margin-top-10">
          {addLinksToUrls(description)}
        </Text>
      </div>
    </Popover>
  );
};

export default NetworkTierDescription;
