import { intersection, partial, string, type, TypeOf } from 'io-ts';

export const TaxonomyTypeV = intersection(
  [
    type({
      taxonomyCode: string,
    }),
    partial({
      taxonomyDisplayName: string,
      subSpecialtyDescription: string,
      taxonomyDescription: string,
    }),
  ],
  'TaxonomyType',
);

export type TaxonomyType = TypeOf<typeof TaxonomyTypeV>;
