import React, { FunctionComponent } from 'react';
import { useTranslation } from '@getvim/translate';
import { Text, SvgStethoscope } from '../../../atoms';
import { ColorNameEnum } from '../../../types';
import { ProfessionalSectionForMultipleOptions } from '../template';
import genderConverter from '../genderConverter';
import messages from '../messages';
import es from '../translates/es.json';

function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

function filterDuplicatesAndNils(items: (string | undefined)[]) {
  return items.filter(notUndefined).filter((item, index, self) => self.indexOf(item) === index);
}

type Props = {
  taxonomies: {
    taxonomyCode: string;
    taxonomyDisplayName?: string;
    subSpecialtyDescription?: string;
    taxonomyDescription?: string;
  }[];
  gender?: 'MALE' | 'FEMALE' | 'UNSPECIFIED' | null;
  application?: string;
};

const SpecializesInProfessionalSection: FunctionComponent<Props> = ({
  taxonomies,
  gender,
  application,
}) => {
  const intl = useTranslation({ es });
  const data = taxonomies
    ? filterDuplicatesAndNils(
        taxonomies.map(({ subSpecialtyDescription }) => subSpecialtyDescription),
      )
    : [];

  const message = `${genderConverter(
    gender,
    intl.formatMessage(messages.he),
    intl.formatMessage(messages.she),
    intl.formatMessage(messages.they),
  )} ${genderConverter(
    gender,
    intl.formatMessage(messages.heSpecializesIn),
    intl.formatMessage(messages.sheSpecializesIn),
    intl.formatMessage(messages.theySpecializeIn),
  )}`;

  return (
    <ProfessionalSectionForMultipleOptions
      data={data}
      message={message}
      className="specializes-section"
      icon={
        application === 'premera' ? (
          <SvgStethoscope />
        ) : (
          <Text className="icon-stethoscope" colorName={ColorNameEnum.themeSecondary} />
        )
      }
    />
  );
};

export default SpecializesInProfessionalSection;
