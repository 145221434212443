import { getSearchEvents } from '@getvim/components-utils-analytics';
import { AnalyticableFilters } from '../../pages/SearchAndAction/formDef';

const filterNameToEvent: { [key in keyof AnalyticableFilters]: string } = {
  distance: 'distance filter click',
  gender: 'gender filter click',
  spokenLanguage: 'spoken language filter click',
  onlyInNetwork: 'only in network',
  onlyBookableProviders: 'only bookable providers filter click',
  geo: 'geo filter click',
};

export default getSearchEvents<AnalyticableFilters>(filterNameToEvent);
