import React, { FunctionComponent } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import classNames from 'classnames';
import { Text, AlignEnum, ColorNameEnum, SizeEnum, WeightEnum } from '@getvim/atomic-ui';
import { SvgClinicalMatch } from '../../atoms';

import './ClinicalMatchBadge.less';

interface Code {
  code: string;
  comparedToAverage: number;
  taxonomy: string;
}

interface ClinicalMatchProps {
  icd?: Code[];
  cpt?: Code[];
  gender?: {
    taxonomy: string;
  }[];
  age?: boolean;
}

export interface ClinicalMatchBadgeProps {
  clinicalMatch?: ClinicalMatchProps;
  popoverClassName?: string;
}

const parseClinicalMatchDetail = (
  startText: string,
  emphasizedText1: string,
  emphasizedText2: string,
  emphasizedTextClass: string,
  endText: string,
) => (
  <div>
    <Text
      inline
      weight={WeightEnum.semibold}
      align={AlignEnum.left}
      size={SizeEnum['14px']}
      colorName={ColorNameEnum.ultraDark}
    >
      {startText}
      &nbsp;
      <Text
        className={emphasizedTextClass}
        align={AlignEnum.left}
        weight={WeightEnum.semibold}
        size={SizeEnum['14px']}
        colorName={ColorNameEnum.ultraDark}
        inline
      >
        {emphasizedText1}
        &nbsp;
      </Text>
    </Text>
    {emphasizedText2 && (
      <Text
        className={emphasizedTextClass}
        align={AlignEnum.left}
        weight={WeightEnum.semibold}
        size={SizeEnum['14px']}
        colorName={ColorNameEnum.ultraDark}
        inline
      >
        {emphasizedText2}
        &nbsp;
      </Text>
    )}
    <Text size={SizeEnum['12px']} align={AlignEnum.left} colorName={ColorNameEnum.dark}>
      {endText}
    </Text>
  </div>
);

const parseClinicalMatchDetails = (clinicalMatch: ClinicalMatchProps) => {
  const details: { text: JSX.Element; icon: JSX.Element }[] = [];
  if (clinicalMatch.icd) {
    const sorted = clinicalMatch.icd.sort((a, b) => a.comparedToAverage - b.comparedToAverage);
    const icd = sorted[sorted.length - 1]; // Highest score
    const text = parseClinicalMatchDetail(
      'Treats this',
      'condition',
      icd.comparedToAverage > 1 ? `${Math.round(icd.comparedToAverage)} times` : '',
      'item-number',
      icd.comparedToAverage > 1 ? 'more than average' : '',
    );
    const icon = <i className="icon-condition-heart-2 text-warning" />;
    details.push({ text, icon });
  }
  if (clinicalMatch.cpt) {
    const sorted = clinicalMatch.cpt.sort((a, b) => a.comparedToAverage - b.comparedToAverage);
    const cpt = sorted[sorted.length - 1]; // Highest score
    const text = parseClinicalMatchDetail(
      'Performs this',
      'procedure',
      cpt.comparedToAverage > 1 ? `${Math.round(cpt.comparedToAverage)} times` : '',
      'item-number',
      cpt.comparedToAverage > 1 ? 'more than average' : '',
    );
    const icon = <i className="icon-syringe-2 age-icon text-orange" />;
    details.push({ text, icon });
  }
  if (clinicalMatch.gender) {
    const text = parseClinicalMatchDetail(
      'Sees patients of this',
      'gender',
      '',
      'item-number',
      'more than average',
    );
    const icon = <i className="icon-gender-2 gender-icon text-pink" />;
    details.push({ text, icon });
  }
  if (clinicalMatch.age) {
    const text = parseClinicalMatchDetail(
      'Sees patients of this',
      'age',
      '',
      'item-number',
      'more than average',
    );
    const icon = <i className="icon-age-2 text-light-blue" />;
    details.push({ text, icon });
  }
  return details;
};

const ClinicalMatchBadge: FunctionComponent<ClinicalMatchBadgeProps> = ({
  clinicalMatch,
  popoverClassName,
}) => {
  if (!clinicalMatch) return null;
  return (
    <OverlayTrigger
      placement="bottom-start"
      overlay={
        <Popover
          id="clinical-match-badge"
          className={classNames('clinical-match-popover', popoverClassName)}
        >
          <div className="popover-content">
            {parseClinicalMatchDetails(clinicalMatch).map(({ icon, text }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="clinical-match-item" key={`clinical-match-item-${index}`}>
                <div className="item-icon">{icon}</div>
                <div className="item-text">{text}</div>
              </div>
            ))}
          </div>
        </Popover>
      }
    >
      <div className="clinical-match-wrap">
        <div className="clinical-match-btn">
          <span className="hidden">
            <SvgClinicalMatch />
          </span>
          <i className="icon-user-doctor-2 badge-icon" />
          <Text
            className="btn-text"
            align={AlignEnum.left}
            size={SizeEnum['12px']}
            colorName={ColorNameEnum.theme}
            inline
          >
            Relevant Expertise
          </Text>
          <Text className="arrow-icon">
            <i className="icon-chevron-down" />
          </Text>
        </div>
      </div>
    </OverlayTrigger>
  );
};

export default ClinicalMatchBadge;
