import { fold } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';

/**
 * Creates a function which takes incoming values and decodes them with the given io-ts type,
 * returning a promise reflecting the result.
 *
 * @param type io-ts type to use for decoding incoming values.
 */
export function decode<Output, Input>(
  type: t.Decoder<Input, Output>,
): (value: Input) => Promise<Output>;
/**
 * Decodes values using io-ts types, returning a promise reflecting the result.
 *
 * @param type io-ts type to use for decoding the value.
 * @param value Value to decode using the given io-ts type.
 */
export function decode<Output, Input>(
  type: t.Decoder<Input, Output>,
  value: Input,
): Promise<Output>;
export function decode<Output, Input>(
  type: t.Decoder<Input, Output>,
  value?: Input,
): ((value: Input) => Promise<Output>) | Promise<Output> {
  switch (arguments.length) {
    case 0:
      throw new Error('Function called with no arguments');
    case 1:
      return decode.bind<null, t.Decoder<Input, Output>, [Input], Promise<Output>>(null, type);
    default:
      return fold<t.Errors, Output, Promise<Output>>(
        (errors) => Promise.reject(new DecodeError(errors)),
        (decodedValue) => Promise.resolve(decodedValue),
        // eslint-disable-next-line prefer-rest-params
      )(type.decode(value || arguments[1]));
  }
}

/**
 * Checks whether error was produced by @see decode due to invalid data.
 */
export function isDecodeError(error: unknown): error is DecodeError {
  return error instanceof DecodeError;
}

/**
 * Custom error class which is rejected by the @see decode function
 * when decoding fails due to invalid data.
 */
export class DecodeError extends Error {
  public name = 'DecodeError';

  public errors: t.Errors;

  constructor(errors: t.Errors) {
    super(PathReporter.report(t.failures(errors)).join('\n'));
    this.errors = errors;
    Object.setPrototypeOf(this, DecodeError.prototype);
  }
}
