import config from '../config';

export const isLogEnabled = () => process.env.NODE_ENV !== 'production' || config.VIM_CLIENT_LOGGER;

export const log = (...data: any[]) => {
  if (isLogEnabled()) {
    console.log(...data); // eslint-disable-line
  }
};

export const error = (...data: any[]) => {
  if (isLogEnabled()) {
    console.error(...data); // eslint-disable-line
  }
};

export const debug = (...data: any[]) => {
  if (isLogEnabled()) {
    console.debug(...data); // eslint-disable-line
  }
};
