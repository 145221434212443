import React from 'react';

function SvgShieldCheck() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      width="26px"
      height="32px"
      viewBox="0 0 26 32"
      version="1.1"
      className="svg-icon svg-shield-check"
    >
      <g id="Refer-by-specialty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Send-list?" transform="translate(-736.000000, -217.000000)">
          <g id="Group" transform="translate(734.000000, 217.000000)">
            <g id="Page-1" transform="translate(2.000000, 0.000000)">
              <path
                d="M11,21.9668 C10.735,21.9668 10.48,21.8618 10.293,21.6738 L6.293,17.6708 C5.902,17.2798 5.902,16.6468 6.293,16.2568 C6.684,15.8648 7.316,15.8648 7.707,16.2568 L10.957,19.5088 L18.253,11.2958 C18.62,10.8828 19.251,10.8448 19.664,11.2128 C20.077,11.5798 20.114,12.2128 19.747,12.6258 L11.747,21.6308 C11.564,21.8368 11.305,21.9588 11.029,21.9668 C11.02,21.9668 11.01,21.9668 11,21.9668"
                id="Fill-4"
                fill="var(--secondary-color)"
              />
              <path
                d="M2,5.3813 L2,17.8893 C2,22.2823 9.517,27.7353 13,29.8373 C16.483,27.7323 24,22.2763 24,17.8893 L24,5.3813 C23.023,5.6453 22.019,5.7793 21,5.7793 C17.934,5.7793 15.079,4.5913 13,2.4863 C10.921,4.5913 8.066,5.7793 5,5.7793 C3.981,5.7793 2.977,5.6453 2,5.3813 M13,32.0003 C12.828,32.0003 12.656,31.9553 12.502,31.8663 C11.991,31.5723 0,24.5973 0,17.8893 L0,3.9873 C0,3.6543 0.166,3.3433 0.442,3.1563 C0.72,2.9713 1.069,2.9343 1.379,3.0623 C2.538,3.5373 3.756,3.7773 5,3.7773 C7.858,3.7773 10.489,2.5313 12.218,0.3583 C12.597,-0.1197 13.403,-0.1197 13.782,0.3583 C15.511,2.5313 18.142,3.7773 21,3.7773 C22.244,3.7773 23.462,3.5373 24.621,3.0623 C24.93,2.9353 25.28,2.9713 25.558,3.1563 C25.834,3.3433 26,3.6543 26,3.9873 L26,17.8893 C26,24.5973 14.009,31.5723 13.498,31.8663 C13.344,31.9553 13.172,32.0003 13,32.0003"
                id="Fill-1"
                fill="var(--main-color)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgShieldCheck;
