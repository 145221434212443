import React, { FunctionComponent } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Formatter from '@getvim/components-utils-formatter';
import { useTranslation } from '@getvim/translate';
import { DistanceChip, Text, ClickableDiv } from '../../atoms';
import ProviderLocationSelector from '../providerLocationsSelector';
import MapSnapshot, { MapSnapshotProps } from './MapSnapshot';
import { AlignEnum, ColorNameEnum, SizeEnum, WeightEnum } from '../../types';
import messages from './messsages';
import es from './translates/es.json';

import './ProviderLocations.less';

type PhoneNumberType = { number: string; countryDialingCode: string };

dayjs.extend(customParseFormat);

type Day = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';
const daysOrder: Day[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const googleMapTemplate = `https://maps.googleapis.com/maps/api/staticmap?zoom=14&size=*|width|*x*|height|*&maptype=roadmap&markers=color:red%7Clabel:C%7C*|point|*&key=*|key|*`;
const bingMapTemplate = `https://dev.virtualearth.net/REST/v1/Imagery/Map/Road/*|point|*/14?mapSize=*|width|*,*|height|*&mapLayer=Basemap&pushpin=*|point|*;37&key=*|key|*`;

type Geo = { latitude: number | string; longitude: number | string };
type OpenHours = { [x in Day]?: { open: string; close: string; isOpen: boolean } };

type Location = {
  id: number;
  geo: Geo;
  phoneNumber?: PhoneNumberType | null;
  officeName?: string | null;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  openHours?: OpenHours | Array<any>;
  distance?: number;
};

type MapConfig = {
  width?: number;
  height?: number;
  mapConfig: {
    key: string;
    templateUrl?: string;
  };
};

type PatientLocation = { geo: Geo; name: string; zip?: string };

type PhysiciansLocationsProps = {
  location: Location;
  patientLocation?: PatientLocation;
  otherLocations?: Location[];
  onLocationChanged?: (newLocationId: any) => void;
  mapConfig: MapConfig;
  showPhoneNumber?: boolean;
  clientZip?: JSX.Element | null;
  phoneNumberActions?: (phoneNumber: PhoneNumberType) => void;
  openingHoursTitle?: string;
  showOnMapBtn?: boolean;
  showMap?: () => void;
  useBingMap?: boolean;
};

function formatOpenHours(time: string) {
  return dayjs(time, 'HH:mm').format('h:mm A');
}

type OpeningHoursType = FunctionComponent<{
  openHours?: OpenHours | Array<any>;
  openingHoursTitle?: string;
}>;

function allDaysAreClosed(openHours: OpenHours) {
  return daysOrder.every((day) => !openHours[day]?.isOpen);
}

const OpeningHours: OpeningHoursType = ({ openHours, openingHoursTitle }) => {
  if (!openHours || Array.isArray(openHours) || allDaysAreClosed(openHours)) return <div />;

  return (
    <div className="office-hours margin-top-30">
      <span className="office-hours__title">{openingHoursTitle}</span>
      {daysOrder.map((day) => {
        const currentDay = openHours[day];
        const { open, close, isOpen } = currentDay || {};
        const isClosed = !isOpen || !open || !close;
        return (
          <div className="day-row" key={day}>
            <Text
              colorName={ColorNameEnum.ultraDark}
              align={AlignEnum.left}
              weight={WeightEnum.semibold}
              className="day-column"
            >
              {day}
            </Text>
            <Text
              colorName={ColorNameEnum.ultraDark}
              align={AlignEnum.left}
              weight={WeightEnum.light}
            >
              {isClosed ? `Closed` : `${formatOpenHours(open!)} - ${formatOpenHours(close!)}`}
            </Text>
          </div>
        );
      })}
    </div>
  );
};

type PhysiciansLocationsType = FunctionComponent<PhysiciansLocationsProps>;

const PhysiciansLocations: PhysiciansLocationsType = ({
  location,
  patientLocation,
  otherLocations,
  onLocationChanged,
  mapConfig,
  showPhoneNumber,
  phoneNumberActions,
  clientZip,
  openingHoursTitle,
  showOnMapBtn = false,
  showMap,
  useBingMap = false,
}) => {
  const intl = useTranslation({ es });
  const clientGeo = patientLocation?.geo;
  const { phoneNumber } = location;
  const distance =
    location.distance !== undefined ? Math.round(location.distance * 10) / 10 : undefined;

  const mapConfiguration = {
    ...mapConfig,
    mapConfig: mapConfig?.mapConfig,
  };

  if (mapConfig?.mapConfig?.templateUrl === undefined) {
    mapConfiguration.mapConfig = useBingMap
      ? { templateUrl: bingMapTemplate, key: mapConfig?.mapConfig?.key }
      : { templateUrl: googleMapTemplate, key: mapConfig?.mapConfig?.key };
  }

  return (
    <div className="address-section">
      <Text
        align={AlignEnum.left}
        weight={WeightEnum.bold}
        size={SizeEnum['16px']}
        colorName={ColorNameEnum.ultraDark}
        className="office-information"
      >
        {intl.formatMessage(messages.officeInformation)}
      </Text>
      <div>
        {onLocationChanged && otherLocations?.length ? (
          <ProviderLocationSelector
            pickedLocation={location}
            otherLocations={otherLocations}
            onLocationChanged={onLocationChanged}
          />
        ) : null}
      </div>
      <div
        className={`title margin-top ${
          otherLocations && otherLocations.length ? undefined : 'single-location'
        }`}
      >
        <Text
          weight={WeightEnum.semibold}
          colorName={ColorNameEnum.ultraDark}
          align={AlignEnum.left}
          className="office-name"
        >
          {location.officeName}
        </Text>
        {location.geo && (clientGeo || distance !== undefined) && (
          <div className="distance-wrapper">
            <DistanceChip
              distance={distance}
              showIcon={false}
              className="text-semibold text-ultra-dark"
            />
            <span className="client-zip">{clientZip}</span>
          </div>
        )}
      </div>
      <Text className="office-address" align={AlignEnum.left} colorName={ColorNameEnum.ultraDark}>
        <span>{`${location.addressLine1}${
          location.addressLine2 ? `, ${location.addressLine2}` : ''
        }`}</span>
        <span>{` ${location.city}, ${location.state} ${location.zip}`}</span>
      </Text>
      {!!phoneNumber && showPhoneNumber && (
        <div className="facility-phone-number">{Formatter.formatPhoneToLocal(phoneNumber)}</div>
      )}
      {location.geo && (
        <div
          className={`map-container margin-top map-container--${showOnMapBtn ? 'hidden' : null}`}
        >
          {showOnMapBtn ? (
            <ClickableDiv className="show-on-map-btn" onClick={showMap}>
              Show on map
            </ClickableDiv>
          ) : (
            <MapSnapshot
              geo={{ latitude: +location.geo.latitude, longitude: +location.geo.longitude }}
              {...(mapConfiguration as Omit<MapSnapshotProps, 'geo'>)}
            />
          )}
        </div>
      )}
      {phoneNumberActions && phoneNumber ? phoneNumberActions(phoneNumber) : null}
      <OpeningHours openHours={location.openHours} openingHoursTitle={openingHoursTitle} />
    </div>
  );
};

export default PhysiciansLocations;
