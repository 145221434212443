import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { useTranslation } from '@getvim/translate';
import Text from '../text';
import { ColorNameEnum, SizeEnum, BookingType } from '../../types';
import './BookingTypeBadge.less';
import messages from './messages';
import es from './translates/es.json';

type BookingTypesWithBadges = Exclude<BookingType, BookingType.NONE>;

interface BookingTypeBadgeProps {
  bookingType: BookingType;
}

const BookingTypeBadge: FunctionComponent<BookingTypeBadgeProps> = ({ bookingType }) => {
  const intl = useTranslation({ es });

  const BadgesBookingTypes: {
    [key in BookingTypesWithBadges]: { text: string; iconClass?: string };
  } = {
    CONCIERGE: {
      text: 'Booking concierge',
      iconClass: 'icon-bell',
    },
    HYBRID: {
      text: 'Hybrid',
      iconClass: 'icon-paper-airplane',
    },
    REQUEST: {
      text: intl.formatMessage(messages.requestBooking),
      iconClass: 'icon-paper-airplane',
    },
    INSTANT: {
      text: intl.formatMessage(messages.instantBooking),
      iconClass: 'icon-lightning-e',
    },
  };

  if (bookingType === BookingType.NONE) return null;

  const badgeData = BadgesBookingTypes[bookingType];

  return (
    <div className="booking-type-badge">
      {!!badgeData.iconClass && (
        <Text
          inline
          colorName={ColorNameEnum.themeSecondary}
          className={classNames('booking-type-icon', badgeData.iconClass)}
        />
      )}
      <Text
        colorName={ColorNameEnum.extraDark}
        inline
        size={SizeEnum['14px']}
        className="booking-type-text"
      >
        {badgeData.text}
      </Text>
    </div>
  );
};

export default BookingTypeBadge;
