import React, { FunctionComponent } from 'react';

const SvgBoardCertification: FunctionComponent = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    className="svg-icon svg-board-certification"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
  >
    <defs>
      <polygon points="-0.000308522485 0 26.0009085 0 26.0009085 9 -0.000308522485 9 -0.000308522485 3.55271368e-15" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-189.000000, -426.000000)">
        <g transform="translate(189.000000, 426.000000)">
          <path
            d="M15.7542,32.0002 C15.5432,32.0002 15.3332,31.9342 15.1552,31.8012 L10.6182,28.4132 L5.0442,27.4172 C4.6202,27.3412 4.2922,27.0022 4.2312,26.5762 L4.0232,25.1442 C3.9422,24.5972 4.3212,24.0902 4.8682,24.0102 C5.4122,23.9342 5.9212,24.3102 6.0012,24.8562 L6.1062,25.5752 L11.2032,26.4862 C11.3572,26.5132 11.5012,26.5762 11.6262,26.6692 L15.7752,29.7672 L20.0242,26.8072 C20.1502,26.7182 20.2972,26.6602 20.4512,26.6372 L25.5752,25.8942 L25.7672,24.8242 C25.8642,24.2802 26.3812,23.9162 26.9272,24.0162 C27.4712,24.1132 27.8332,24.6332 27.7352,25.1772 L27.4172,26.9572 C27.3412,27.3802 27.0022,27.7082 26.5762,27.7702 L20.9732,28.5832 L16.3252,31.8202 C16.1532,31.9402 15.9542,32.0002 15.7542,32.0002"
            fill="var(--main-color)"
          />
          <path
            d="M28.1058,17.4475 L29.3818,20.0005 L25.3878,20.0005 L25.4208,19.9005 C25.7608,19.7405 26.0008,19.4005 26.0008,19.0005 L26.0008,14.0005 L29.3818,14.0005 L28.1058,16.5525 C27.9648,16.8345 27.9648,17.1655 28.1058,17.4475 L28.1058,17.4475 Z M8.0538,18.0005 L8.0008,17.8395 L8.0008,12.0005 L24.0008,12.0005 L24.0008,17.8395 L23.9468,18.0005 L8.0538,18.0005 Z M2.6188,20.0005 L3.8948,17.4475 C4.0358,17.1655 4.0358,16.8345 3.8948,16.5525 L2.6188,14.0005 L6.0008,14.0005 L6.0008,19.0005 C6.0008,19.4005 6.2398,19.7405 6.5798,19.9005 L6.6128,20.0005 L2.6188,20.0005 Z M30.1188,17.0005 L31.8948,13.4475 C32.0498,13.1375 32.0338,12.7695 31.8508,12.4745 C31.6688,12.1795 31.3468,12.0005 31.0008,12.0005 L26.0008,12.0005 L26.0008,11.0005 C26.0008,10.4475 25.5528,10.0005 25.0008,10.0005 L7.0008,10.0005 C6.4478,10.0005 6.0008,10.4475 6.0008,11.0005 L6.0008,12.0005 L1.0008,12.0005 C0.6538,12.0005 0.3328,12.1795 0.1498,12.4745 C-0.0332,12.7695 -0.0492,13.1375 0.1058,13.4475 L1.8818,17.0005 L0.1058,20.5525 C-0.0492,20.8635 -0.0332,21.2315 0.1498,21.5265 C0.3328,21.8205 0.6538,22.0005 1.0008,22.0005 L8.0008,22.0005 C8.3218,22.0005 8.6238,21.8455 8.8118,21.5845 C8.9998,21.3235 9.0508,20.9895 8.9488,20.6835 L8.7208,20.0005 L23.2798,20.0005 L23.0518,20.6835 C22.9498,20.9895 23.0018,21.3235 23.1888,21.5845 C23.3768,21.8455 23.6788,22.0005 24.0008,22.0005 L31.0008,22.0005 C31.3468,22.0005 31.6688,21.8205 31.8508,21.5265 C32.0338,21.2315 32.0498,20.8635 31.8948,20.5525 L30.1188,17.0005 Z"
            fill="var(--secondary-color)"
          />

          <g transform="translate(3.000000, 0.000200)">
            <path
              d="M25.0013,9 C24.6513,9 24.3133,8.817 24.1293,8.491 L22.8033,6.139 L17.4513,5.363 C17.2893,5.339 17.1353,5.276 17.0033,5.179 L13.0003,2.241 L8.9973,5.179 C8.8653,5.276 8.7113,5.339 8.5493,5.363 L3.1973,6.139 L1.8713,8.491 C1.6003,8.971 0.9893,9.142 0.5093,8.871 C0.0273,8.6 -0.1427,7.99 0.1293,7.509 L1.6963,4.729 C1.8493,4.458 2.1173,4.274 2.4243,4.23 L8.0153,3.419 L12.4083,0.194 C12.7603,-0.065 13.2403,-0.065 13.5923,0.194 L17.9853,3.419 L23.5763,4.23 C23.8833,4.274 24.1513,4.458 24.3043,4.729 L25.8713,7.509 C26.1433,7.99 25.9733,8.6 25.4913,8.871 C25.3363,8.958 25.1673,9 25.0013,9"
              fill="var(--main-color)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgBoardCertification;
