/* eslint-disable @typescript-eslint/no-shadow */
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Loader,
  Text,
  ColorNameEnum,
  SizeEnum,
  WeightEnum,
  Button,
  DistanceChip,
  deprecated,
  Container,
  NoResultsX,
  ResultCard,
  Availability,
  AvailabilitySizeEnum,
  BookingType,
  BookingTypeBadge,
  PreferredProviderBadge,
  AsoMetadata,
  NetworkStatusEnum,
  NetworkStatusChip,
} from '@getvim/atomic-ui';
import { useToasts } from 'react-toast-notifications';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';
import { useTheme } from '@getvim/components-hooks-use-theme';
import { AnalyticsInstance, SearchActionEvents } from '@getvim/components-utils-analytics';
import { Atoms, Molecules } from '../../atomic';
import { getProviderAvailability, searchActionAnalytics } from '../../api/api';
import Formatter from '../../utils/formatter';
import ResultCardBanner from '../ResultCardBanner/ResultCardBanner';
import { ProviderType } from '../../models/Provider';
import { LocationType } from '../../models/Location';
import { SearchResultsType } from '../../models/SearchResults';
import SearchAction from '../../models/SearchAction';
import SearchEvents from '../../utils/events/searchEvents';
import { prepareProviderDataToCopy, copyToClipboard } from '../../utils/copyToClipboard';
import DetailsButton from '../ProviderDetails/DetailsButton';

import './SearchResults.less';
import { ApplicationConfig } from '../../api/responseTypes';

export interface SearchResultsProps {
  searchResults?: SearchResultsType;
  onBook: (payload: { npi: string; address: string }) => void;
  onFinish: (payload?: any) => void;
  onChangePage: (p: number) => void;
  resultsLimit: number;
  pageNumber: number;
  memberToken?: string;
  queryId: string;
  memberSessionId?: string | null;
  memberPhoneNumber?: string;
  analytics?: AnalyticsInstance;
  onSelect: (npi?: string) => void;
  onConfirm: (provider: ProviderType, location: LocationType) => void;
  displayCopyButton: boolean;
  portal: boolean;
  asoMetadata?: AsoMetadata;
  selectedProviderLocation?: {
    provider: ProviderType;
    location: LocationType;
  };
  setSelectedProvider: (
    newProviderLocation:
      | {
          provider: ProviderType;
          location: LocationType;
        }
      | undefined,
  ) => void;
  insurer?: string;
  applicationConfig: ApplicationConfig;
}

function scrollResultCardToViewPort(npi?: string) {
  if (npi) {
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    const element = document.getElementById(`result-card-${npi}`);
    if (isIE) {
      element?.scrollIntoView(true);
    } else {
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}

const SearchResults: FunctionComponent<SearchResultsProps> = ({
  insurer,
  searchResults,
  onSelect,
  onConfirm,
  onBook,
  onFinish,
  resultsLimit,
  onChangePage,
  pageNumber,
  memberToken,
  analytics,
  queryId,
  memberSessionId,
  memberPhoneNumber,
  asoMetadata,
  selectedProviderLocation,
  setSelectedProvider,
  applicationConfig,
  displayCopyButton,
  portal,
}) => {
  const { addToast, removeAllToasts } = useToasts();
  const theme = useTheme();
  const npi = selectedProviderLocation?.provider.npi;
  const [availabilityByNpi, setAvailabilityByNpi] = useState<{ [npi: string]: BookingType }>({});
  const [copiedData, setCopiedData] = useState<string>('');

  const { provider: selectedProvider, location: selectedLocation } = selectedProviderLocation || {
    provider: undefined,
    location: undefined,
  };

  const sdkEvents = useSdkEvents();

  useEffect(() => {
    onSelect(npi);
    setTimeout(() => scrollResultCardToViewPort(npi), 20);
  }, [onSelect, npi]);

  useEffect(() => {
    const getAvailability = async (provider: ProviderType) => {
      if (availabilityByNpi[provider.npi]) return availabilityByNpi[provider.npi];

      const location =
        provider.npi === selectedProvider?.npi ? selectedLocation : provider.locations[0];
      const availabilityData = await getProviderAvailability(
        provider.npi,
        location!.address,
        location!.geo,
        insurer,
        memberToken,
      );

      return availabilityData.data[0].bookingType;
    };

    if (searchResults?.providers) {
      for (const provider of searchResults.providers) {
        getAvailability(provider)
          .then((bookingType) => {
            setAvailabilityByNpi((values) => ({
              ...values,
              [provider.npi]: bookingType as BookingType,
            }));
          })
          .catch();
      }
    }
    // eslint-disable-next-line
  }, [searchResults?.providers, selectedLocation]);

  const portalCopiedDataToast = () => {
    if (portal && displayCopyButton) {
      addToast('Details copied', {
        appearance: 'success',
      });
    }
  };

  // Added to prevent refresh on Provider Details when toast from Search results is closed
  const removeCopyToasts = () => {
    if (displayCopyButton) removeAllToasts();
  };

  return !searchResults || !searchResults.providers.length ? (
    <div className="no-results text-center">
      {!searchResults ? (
        <div>
          <div className="svg-72 margin-bottom-30 margin-top-50">
            <Atoms.SvgSearchDoctor />
          </div>
          <Text
            weight={WeightEnum.light}
            colorName={ColorNameEnum.dark}
            className="title-36 no-margin"
          >
            Find a doctor your patient will &nbsp;
            <i className="icon-heart-vim i-va-fix-3 text-pink" />
          </Text>
        </div>
      ) : (
        <div className="no-results text-center margin-top-50">
          <Container>
            <Text
              weight={WeightEnum.bold}
              className="margin-top-30"
              size={SizeEnum['18px']}
              colorName={ColorNameEnum.theme}
            >
              Sorry,
            </Text>
            <Text
              size={SizeEnum['14px']}
              colorName={ColorNameEnum.extraDark}
              className="margin-top"
            >
              We couldn't find what you're looking for.
            </Text>
            <Text size={SizeEnum['14px']} colorName={ColorNameEnum.extraDark}>
              Please try a new search.
            </Text>
            <div className="margin-bottom-30 margin-top-50">
              <NoResultsX />
            </div>
          </Container>
        </div>
      )}
    </div>
  ) : (
    <div className="search-results" data-qa-copied-provider={copiedData}>
      {searchResults.providers.map((provider) => {
        const bookingType = availabilityByNpi[provider.npi];
        const location: LocationType & { bookingType: BookingType } = {
          ...(selectedProvider?.npi === provider?.npi
            ? (selectedLocation as LocationType)
            : provider.locations[0]),
          bookingType,
        };
        const taxonomies = Formatter.formatTaxonomies(provider.taxonomies);
        const fullProviderName = Formatter.formatProviderTitle(provider);
        const isSelected = selectedProvider?.npi === provider.npi;
        const cardSelectedClassName = isSelected ? 'card-selected' : undefined;
        const distance =
          location.distance != undefined ? Math.round(location.distance * 10) / 10 : undefined;

        return (
          <ResultCard
            sideBannerMode
            id={provider.npi}
            key={provider.npi}
            showBottomActionBanner={!portal && isSelected}
            resultImg={<deprecated.ProviderLogo provider={provider} containerClass="big" />}
            resultLeftDetail={
              <div>
                {location.network && (
                  <NetworkStatusChip
                    networkStatus={NetworkStatusEnum[location.network.status]}
                    networkStatusTier={location.network.tier}
                    asoMetadata={asoMetadata}
                    hideUnverified
                  />
                )}
                <span data-qa-gender={provider.gender} style={{ display: 'none' }} />
              </div>
            }
            resultMainText={fullProviderName}
            resultSecondaryText={taxonomies}
            addressTitle={location.officeName}
            address={location.address}
            addressColor={
              provider.internalProvider ? ColorNameEnum.themeSecondary : ColorNameEnum.ultraDark
            }
            locationDistance={<DistanceChip distance={distance} />}
            primaryActionBtn={
              <Button
                buttonType="small"
                text={displayCopyButton ? 'Copy Details' : 'Select'}
                width="small"
                onClick={() => {
                  onConfirm(provider, location);
                  setSelectedProvider({ provider, location });
                  if (displayCopyButton) {
                    const copyData = prepareProviderDataToCopy(provider);
                    copyToClipboard(copyData);
                    setCopiedData(copyData);
                    portalCopiedDataToast();
                  }
                  searchActionAnalytics(
                    queryId,
                    SearchAction.SELECT,
                    provider.npi,
                    location.id,
                    memberSessionId,
                    provider.ranking,
                  );
                }}
              />
            }
            secondaryActionBtn={
              <DetailsButton
                insurer={insurer}
                provider={provider}
                setSelectedProvider={setSelectedProvider}
                asoMetadata={asoMetadata}
                displayCopyButton={displayCopyButton}
                theme={theme}
                analytics={analytics}
                onDetailsModalShow={() => {
                  removeCopyToasts();
                  searchActionAnalytics(
                    queryId!,
                    SearchAction.DETAILS,
                    provider.npi,
                    location.id,
                    memberSessionId,
                    provider.ranking,
                  );
                  const { eventName, params } = SearchActionEvents.detailsClick({
                    npi: provider.npi,
                    queryId,
                    memberSessionId,
                    address: location.address,
                    ranking: provider.ranking,
                  });
                  analytics?.track(eventName, params);
                }}
                onSelectProvider={() => {
                  portalCopiedDataToast();
                  onConfirm(provider, location);
                  searchActionAnalytics(
                    queryId,
                    SearchAction.SELECT,
                    provider.npi,
                    location.id,
                    memberSessionId,
                    provider.ranking,
                  );
                }}
              />
            }
            verticalMode
            showShadow={false}
            horizontalActionButtons
            showLocation
            resultBottomActionBanner={
              selectedLocation && selectedProvider && selectedProvider.npi === provider.npi ? (
                <ResultCardBanner
                  insurer={insurer}
                  npi={provider.npi}
                  fullProviderName={fullProviderName}
                  location={location}
                  onBook={onBook}
                  onCancel={() => setSelectedProvider(undefined)}
                  displayCopyButton={displayCopyButton}
                  onShareSuccess={(correlationId) => {
                    addToast('The provider’s details were sent to the patient', {
                      appearance: 'success',
                    });
                    searchActionAnalytics(
                      queryId,
                      SearchAction.SHARE,
                      provider.npi,
                      location.id,
                      memberSessionId,
                      provider.ranking,
                      correlationId,
                    );
                  }}
                  onShareClick={() => {
                    const { eventName, params } = SearchActionEvents.shareClick({
                      npi: provider.npi,
                      queryId,
                      memberSessionId,
                      address: location.address,
                      ranking: provider.ranking,
                    });

                    analytics?.track(eventName, params);
                  }}
                  onShareSubmitClick={() => {
                    const { eventName, params } = SearchActionEvents.shareSubmitClick({
                      npi: provider.npi,
                      queryId,
                      memberSessionId,
                      address: location.address,
                      ranking: provider.ranking,
                    });

                    analytics?.track(eventName, params);
                  }}
                  onShareFailed={(error) => {
                    addToast(
                      'An error has occurred and we were unable to send SMS to patient, please try again',
                      { appearance: 'error' },
                    );
                    const { eventName, params } = SearchActionEvents.shareFailed({
                      error,
                      npi: provider.npi,
                      locationId: location.id,
                      queryId,
                      memberSessionId,
                    });

                    analytics?.track(eventName, params);
                  }}
                  onClose={onFinish}
                  memberToken={memberToken}
                  memberPhoneNumber={memberPhoneNumber}
                  applicationConfig={applicationConfig}
                />
              ) : undefined
            }
            resultTopBadges={
              <span>
                {provider.hvns ? <Atoms.HighValueBadge /> : undefined}
                {provider.clinicalMatch ? (
                  <span className="hidden-xs hidden-sm">
                    <Molecules.ClinicalMatchBadge
                      clinicalMatch={provider.clinicalMatch}
                      popoverClassName={cardSelectedClassName}
                    />
                  </span>
                ) : undefined}
              </span>
            }
          >
            <span className="visible-xs visible-sm">
              {provider.clinicalMatch && (
                <Molecules.ClinicalMatchBadge
                  clinicalMatch={provider.clinicalMatch}
                  popoverClassName={cardSelectedClassName}
                />
              )}
            </span>
            {provider.preferredProvider && <PreferredProviderBadge />}
            <Availability
              acceptNewPatients={provider.acceptNewPatients || location.acceptNewPatients}
              bookingHorizon={location.bookingHorizon}
              size={AvailabilitySizeEnum.small}
              shortText
            />
            {location.bookingType ? (
              <BookingTypeBadge bookingType={location.bookingType} />
            ) : (
              <Loader type="dots" color="grey" size="small" padding="none" />
            )}
          </ResultCard>
        );
      })}
      <deprecated.Pagination
        active={pageNumber}
        maxButtons={3}
        pagesNumber={Math.ceil(searchResults.total / resultsLimit)}
        onClick={(newPageNumber) => {
          onChangePage(newPageNumber);
          const { eventName, params } = SearchEvents.changePage({
            pageNumber: newPageNumber,
            prevPage: pageNumber,
            memberSessionId,
            queryId,
          });
          analytics?.track(eventName, params);
        }}
      />
    </div>
  );
};

export default SearchResults;
