import { array, boolean, number, partial, string, type, TypeOf } from 'io-ts';

const CodeTypeV = type({
  code: string,
  comparedToAverage: number,
  taxonomy: string,
});

export const ClinicalMatchTypeV = partial(
  {
    icd: array(CodeTypeV),
    cpt: array(CodeTypeV),
    gender: array(
      type({
        taxonomy: string,
      }),
    ),
    age: boolean,
  },
  'ClinicalMatchType',
);

export type ClinicalMatchType = TypeOf<typeof ClinicalMatchTypeV>;
