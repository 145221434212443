interface Config {
  VIM_PUBLIC_API_URL: string;
  VIM_SEGMENT_API_KEY: string;
  VIM_GOOGLE_API_KEY: string;
  VIM_MAP_CONFIG: {
    templateUrl?: string;
    key: string;
  };
  VIM_CLIENT_LOGGER: boolean;
  VIM_BOOKING_WIDGET_ENV: 'local' | 'staging' | 'sandbox' | 'prod' | 'dev';
  rollout__key: string;
  VIM_MAPS_GOOGLE_KEY: string;
}

const defaults: Partial<Config> = {
  VIM_CLIENT_LOGGER: false,
};

const VIM_MAP_CONFIG = {
  key: 'AlfN8LOhsiEWbzcbwbX8KhleJn-s9GyFbqdEtYj7aenzic4KivK6Zv1svMuYPEh3',
  templateUrl:
    'https://dev.virtualearth.net/REST/v1/Imagery/Map/Road/*|point|*/14' +
    '?mapSize=*|width|*,*|height|*&mapLayer=Basemap&pushpin=*|point|*;37&key=*|key|*',
};

export default { ...defaults, ...window.$vim_environment, VIM_MAP_CONFIG } as Config;
