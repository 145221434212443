import React, { FunctionComponent, useEffect, useState } from 'react';
import debounce from 'debounce';
import { deprecated } from '@getvim/atomic-ui';
import {
  typeaheadEvent,
  EventCreatorType,
  TypeaheadEventType,
} from '@getvim/components-utils-analytics';
import isEqual from 'lodash.isequal';
import uniqBy from 'lodash.uniqby';
import { FreeTextResponse, FreeTextTaxonomy } from '../../api/responseTypes';
import SearchType from '../../models/SearchType';
import { FreeTextOption, FreeTextRequest } from '../../api/requestTypes';
import authHandler from '../../api/authHandler';

const lastTerm: { [key: string]: string[] } = {};

type ThemeType = {
  mainColor: string;
  secondaryColor: string;
};

type ValueCompareType = (value: string[], optionValue: string[]) => boolean;

export interface FreeTextTypeaheadProps {
  value?: string[] | string | null;
  valueCompare?: ValueCompareType | ValueCompareType[];
  placeholder: string;
  onChange: (options: FreeTextOption[] | FreeTextOption) => void;
  fetchFreeText: (args: FreeTextRequest) => Promise<FreeTextResponse>;
  onTypeaheadEntered?: (
    event: TypeaheadEventType<FreeTextTaxonomy>,
    eventCreator: EventCreatorType,
  ) => void;
  type: Exclude<SearchType, SearchType.PROVIDER>;
  theme: ThemeType;
  defaultOptions?: FreeTextOption[];
  insurer?: string | null;
}

function parseIcdCptDisplay(code: string, description: string): string {
  // If code includes '_' then it's a range of codes, we don't want to display it
  return code.includes('_') ? description : `${description} - ${code}`;
}

const FreeTextTypeahead: FunctionComponent<FreeTextTypeaheadProps> = ({
  placeholder,
  onChange,
  value,
  valueCompare,
  fetchFreeText,
  type,
  onTypeaheadEntered = () => {},
  theme,
  defaultOptions,
  insurer,
}) => {
  const [options, setOptions] = useState<FreeTextOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);

  useEffect(() => {
    const arrayedValue = (Array.isArray(value) ? value : [value]) as string[];
    const compactedValue = arrayedValue.filter((x) => x);
    if (!compactedValue || !compactedValue.length) {
      setOptions(defaultOptions || []);
    } else if (!options.length) {
      onTaxonomyChanged(compactedValue);
    }
  }, [value, defaultOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTaxonomyChanged = (freeText: string[]) => {
    if (freeText && freeText.length) {
      setIsLoading(true);
      lastTerm[type] = freeText;
      Promise.all(
        freeText.map((term) =>
          fetchFreeText({
            freeText: term,
            types: [type],
            insurer,
          }),
        ),
      )
        .then((freeTextResults) => {
          setIsLoading(false);
          if (isEqual(lastTerm[type], freeText)) {
            const allFreeTextOptions = freeTextResults.reduce<{ value: string[]; label: string }[]>(
              (acc, { data }) => {
                const parsedOptions = data.suggestions[type].map((currTaxonomy) => {
                  const codes = currTaxonomy.filters.map(
                    ({ value: { taxonomyCode } }) => taxonomyCode,
                  );
                  const [firstCode] = codes;
                  return {
                    value: codes,
                    label:
                      type === SearchType.NUCC
                        ? currTaxonomy.searchTerm
                        : parseIcdCptDisplay(firstCode, currTaxonomy.searchTerm),
                  };
                });

                return [...acc, ...parsedOptions];
              },
              [],
            );

            const distinctOptions = uniqBy(allFreeTextOptions, 'label');

            const allTaxonomies = freeTextResults.reduce<FreeTextTaxonomy[]>((acc, { data }) => {
              return [...acc, ...data.suggestions[type]];
            }, []);

            setOptions(distinctOptions);
            onTypeaheadEntered(
              { type, term: freeText.join(', '), results: allTaxonomies },
              (queryId, memberSessionId) => [
                typeaheadEvent.contentEntered<FreeTextTaxonomy>({
                  queryId,
                  memberSessionId,
                  results: allTaxonomies,
                  type,
                  term: freeText.join(', '),
                  formatter: (results) => ({
                    taxonomies: results.map((currRes) => ({
                      searchTerm: currRes.searchTerm,
                      codes: currRes.filters.map(({ value: { taxonomyCode } }) => taxonomyCode),
                    })),
                  }),
                }),
              ],
            );
          }
        })
        .catch((err) => {
          if (defaultOptions && (!options || !options.length)) {
            setOptions(defaultOptions);
          }
          authHandler(err);
        })
        .then(() => setIsLoading(false));
    }
  };

  return (
    <deprecated.SelectInput<FreeTextOption>
      placeholder={placeholder}
      options={options}
      onChange={(v) => {
        if (firstFetch) {
          setFirstFetch(false);
        }
        onChange(type === SearchType.NUCC ? v : v?.[0]);
      }}
      value={value}
      isLoading={isLoading}
      onInputChange={debounce((text: string) => {
        if (text) onTaxonomyChanged([text]);
      })}
      filterOption={() => true}
      theme={theme}
      valueCompare={valueCompare}
      firstFetch={firstFetch}
    />
  );
};

export default FreeTextTypeahead;
