import {
  boolean,
  intersection,
  nullType,
  number,
  partial,
  string,
  type,
  TypeOf,
  union,
} from 'io-ts';
import { BookingHorizonEnum, BookingType, NetworkStatusEnum } from '@getvim/atomic-ui';
import { enumType } from '../utils/io-ts/io-ts-enum';
import { PhoneNumberTypeV } from './PhoneNumber';
import { GeoCodeTypeV } from './GeoCode';
import { EmptyArrayV } from './utils';

const DayOpenHoursV = type(
  {
    open: string,
    close: string,
    isOpen: boolean,
  },
  'DayOpenHours',
);

export const OpenHoursV = partial(
  {
    Mon: DayOpenHoursV,
    Tue: DayOpenHoursV,
    Wed: DayOpenHoursV,
    Thu: DayOpenHoursV,
    Fri: DayOpenHoursV,
    Sat: DayOpenHoursV,
    Sun: DayOpenHoursV,
  },
  'OpenHours',
);

export const LocationTypeV = intersection(
  [
    type({
      id: number,
      geo: GeoCodeTypeV,
      address: string,
    }),
    partial({
      fax: union([PhoneNumberTypeV, nullType]),
      timezone: string,
      officeName: union([string, nullType]),
      phoneNumber: union([PhoneNumberTypeV, nullType]),
      bookingHorizon: union([enumType(BookingHorizonEnum, 'BookingHorizonEnum'), nullType]),
      addressLine1: string,
      addressLine2: string,
      city: string,
      state: string,
      zip: string,
      acceptNewPatients: union([boolean, nullType]),
      distance: number,
      network: intersection([
        type({ status: enumType(NetworkStatusEnum, 'NetworkStatusEnum') }),
        partial({ tier: string }),
      ]),
      openHours: union([OpenHoursV, EmptyArrayV]),
      sampleReview: string,
      bestDrCommunityFeedback: string,
      bestOfficeCommunityFeedback: string,
      bookingType: enumType(BookingType, 'BookingTypeEnum'),
    }),
  ],
  'LocationType',
);

export type LocationType = TypeOf<typeof LocationTypeV>;
