import React, { FunctionComponent } from 'react';
import { deprecated } from '@getvim/atomic-ui';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';

const SessionExpiredErrorPage: FunctionComponent = () => {
  const sdkEvents = useSdkEvents();
  return (
    <deprecated.ErrorPage
      title="Your session expired"
      description="Please close this window and try again"
      buttonText="Close This Window"
      onClose={sdkEvents.closed}
      onSubmit={sdkEvents.closed}
    />
  );
};

export default SessionExpiredErrorPage;
