import React, { FunctionComponent } from 'react';
import { useTranslation } from '@getvim/translate';
import { Text, SvgLanguage } from '../../../atoms';
import { ColorNameEnum } from '../../../types';
import { ProfessionalSectionForMultipleOptions } from '../template';
import genderConverter from '../genderConverter';
import messages from '../messages';
import es from '../translates/es.json';

type Props = {
  languages?: {
    id: string;
    iso3?: string;
    name: string;
    nativeName: string;
  }[];
  gender?: 'MALE' | 'FEMALE' | 'UNSPECIFIED' | null;
  application?: string;
  nativeLanguageLabels?: boolean;
};

function uniqueValuesFilter<T>(currentValue: T, currentIndex: number, sourceArray: T[]) {
  return sourceArray.indexOf(currentValue) === currentIndex;
}

const LanguagesSection: FunctionComponent<Props> = ({
  languages,
  gender,
  application,
  nativeLanguageLabels = false,
}) => {
  const intl = useTranslation({ es });
  const message = genderConverter(
    gender,
    intl.formatMessage(messages.heSpeaks),
    intl.formatMessage(messages.sheSpeaks),
    intl.formatMessage(messages.theySpeak),
  );

  let languagesArray = languages
    ? languages.map(({ name, nativeName }) => (nativeLanguageLabels ? nativeName : name))
    : [];
  if (languagesArray.length > 0) {
    const englishPosition = languagesArray.indexOf('English');
    if (englishPosition !== -1) languagesArray.splice(englishPosition, 1);
  }
  languagesArray.unshift('English'); // set English as default in case there is'nt
  languagesArray = languagesArray.filter(uniqueValuesFilter);

  return (
    <ProfessionalSectionForMultipleOptions
      data={languagesArray}
      className="languages-section"
      message={message}
      icon={
        application === 'premera' ? (
          <SvgLanguage />
        ) : (
          <Text className="icon-globe" colorName={ColorNameEnum.themeSecondary} />
        )
      }
    />
  );
};

export default LanguagesSection;
