import React, { useState, FunctionComponent } from 'react';
import { Button, CheckboxInput, deprecated } from '@getvim/atomic-ui';
import { ThemePropsType } from '@getvim/components-hooks-use-theme';
import { EventCreatorType } from '@getvim/components-utils-analytics';
import classNames from 'classnames';
import SearchEvents from '../../utils/events/searchEvents';

import { FormFiltersValues, BarFiltersType } from '../../pages/SearchAndAction/formDef';
import './FiltersBar.less';

type FiltersBarProps = {
  filters: FormFiltersValues;
  onChange: (filters: Partial<BarFiltersType>, analyticsEventCreator?: EventCreatorType) => void;
  onReset: () => void;
  enableReset: boolean;
  enableOnlyInNetwork: boolean;
  theme: ThemePropsType;
  lightBg: boolean;
};

const FiltersBar: FunctionComponent<FiltersBarProps> = ({
  filters,
  onChange,
  onReset,
  enableReset,
  theme,
  enableOnlyInNetwork,
  lightBg = false,
}) => {
  const [isDistanceTouched, setIsDistanceTouched] = useState(false);
  const overrideItems = [
    {
      id: 'mi10',
      value: 5,
      text: '5 mi',
    },
    {
      id: 'mi25',
      value: 10,
      text: '10 mi',
    },
    {
      id: 'mi50',
      value: 20,
      text: '20 mi',
    },
    {
      id: 'mi100',
      value: 50,
      text: '50 mi',
    },
  ];

  return (
    <div className={classNames('filters-widget', { 'light-bg': lightBg })}>
      <div className="filter-item">
        <deprecated.DistancePopover
          value={filters.distance}
          theme={theme}
          isTouched={isDistanceTouched}
          overrideItems={overrideItems}
          onChange={(selectedDistance) => {
            setIsDistanceTouched(true);
            onChange({ distance: selectedDistance }, (queryId, memberSessionId) => {
              return [
                SearchEvents.filterClick({
                  filterName: 'distance',
                  filterValue: selectedDistance,
                  memberSessionId,
                  queryId,
                }),
              ];
            });
          }}
        />
      </div>
      <div className="filter-item">
        <deprecated.GenderPopover
          theme={theme}
          btnType="dropdown"
          value={filters.gender}
          onChange={(selectedGender) => {
            onChange({ gender: selectedGender }, (queryId, memberSessionId) => {
              return [
                SearchEvents.filterClick({
                  filterName: 'gender',
                  filterValue: selectedGender,
                  memberSessionId,
                  queryId,
                }),
              ];
            });
          }}
        />
      </div>
      <div className="filter-item">
        <deprecated.LanguagePopover
          theme={theme}
          btnType="dropdown"
          value={filters.spokenLanguage}
          onChange={(selectedLanguage) => {
            onChange({ spokenLanguage: selectedLanguage }, (queryId, memberSessionId) => {
              return [
                SearchEvents.filterClick({
                  filterName: 'spokenLanguage',
                  filterValue: selectedLanguage,
                  memberSessionId,
                  queryId,
                }),
              ];
            });
          }}
        />
      </div>
      {enableOnlyInNetwork && (
        <div className="filter-item">
          <CheckboxInput
            className="trans-checkbox nowrap dark-checkbox"
            id="onlyInNetwork"
            checked={filters.onlyInNetwork}
            label="In-network only"
            disabled={!enableOnlyInNetwork}
            onChange={(value: any) => {
              onChange(
                { onlyInNetwork: value.currentTarget.checked },
                (queryId, memberSessionId) => {
                  return [
                    SearchEvents.filterClick({
                      filterName: 'onlyInNetwork',
                      filterValue: value.currentTarget.checked,
                      memberSessionId,
                      queryId,
                    }),
                  ];
                },
              );
            }}
          />
        </div>
      )}
      <div className="filter-item">
        <CheckboxInput
          className="trans-checkbox nowrap dark-checkbox"
          id="onlyBookableProviders"
          checked={filters.onlyBookableProviders}
          label="Offers online scheduling"
          onChange={(value: any) => {
            onChange(
              { onlyBookableProviders: value.currentTarget.checked },
              (queryId, memberSessionId) => {
                return [
                  SearchEvents.filterClick({
                    filterName: 'onlyBookableProviders',
                    filterValue: value.currentTarget.checked,
                    memberSessionId,
                    queryId,
                  }),
                ];
              },
            );
          }}
        />
      </div>
      {enableReset ? (
        <div className="clear-filter-btn">
          <Button
            buttonType="link"
            color="white"
            onClick={() => {
              setIsDistanceTouched(false);
              onReset();
            }}
            data-id="reset-filters"
          >
            <i className="icon-x" /> Clear filters
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default FiltersBar;
