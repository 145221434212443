import React, { FunctionComponent } from 'react';
import { Text } from '../../../atoms';
import { AlignEnum, ColorNameEnum, WeightEnum } from '../../../types';
import './ProfessionalSection.less';

export type Props = {
  data?: JSX.Element | string | null;
  message: string;
  icon: JSX.Element;
};

const ProfessionalSection: FunctionComponent<Props> = ({ data, message, icon }) => {
  if (!data) return <div />;
  return (
    <div className="pro-section">
      <Text align={AlignEnum.left} colorName={ColorNameEnum.ultraDark}>
        {message}
      </Text>
      <div className="no-margin">
        {typeof data === 'string' ? (
          <Text
            align={AlignEnum.left}
            weight={WeightEnum.semibold}
            colorName={ColorNameEnum.ultraDark}
          >
            {data}
          </Text>
        ) : (
          data
        )}
      </div>
      <div className="section-icon">{icon}</div>
    </div>
  );
};

export default ProfessionalSection;
