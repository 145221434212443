import React, { FunctionComponent } from 'react';
import { Text } from '../../../atoms';
import { AlignEnum, ColorNameEnum, WeightEnum } from '../../../types';
import ProfessionalSection, { Props as ProfessionalSectionProps } from './ProfessionalSection';

type Props = Omit<ProfessionalSectionProps, 'data'> & {
  data?: string[];
  className?: string;
};

const ProfessionalSectionForMultipleOptions: FunctionComponent<Props> = ({
  data: dataArray,
  className,
  ...other
}) => {
  const data = (
    <div className={className}>
      {dataArray &&
        dataArray.map((value) => (
          <Text
            key={`${value}`}
            align={AlignEnum.left}
            weight={WeightEnum.semibold}
            colorName={ColorNameEnum.ultraDark}
          >
            {value}
          </Text>
        ))}
    </div>
  );

  return <ProfessionalSection data={data} {...other} />;
};

export default ProfessionalSectionForMultipleOptions;
