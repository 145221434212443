import React, { FunctionComponent } from 'react';

const SvgEducation: FunctionComponent = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    className="svg-icon svg-education"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
  >
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M27.0001,17.1383 C23.6141,19.0123 19.8231,19.9993 16.0001,19.9993 C12.1771,19.9993 8.3861,19.0123 5.0001,17.1383 L5.0001,11.4763 L15.5191,16.8883 C15.6631,16.9623 15.8191,16.9993 15.9771,16.9993 C16.1351,16.9993 16.2931,16.9623 16.4371,16.8873 L27.0001,11.4053 L27.0001,17.1383 Z M16.0231,2.1103 L28.7641,8.2363 L15.9751,14.8743 L3.2361,8.3193 L16.0231,2.1103 Z M31.4341,7.3003 L16.4551,0.0983 C16.1801,-0.0327 15.8601,-0.0327 15.5851,0.1003 L0.5631,7.3943 C0.2231,7.5593 0.0051,7.9033 0.0001,8.2823 C-0.0039,8.6603 0.2061,9.0093 0.5421,9.1823 L3.0001,10.4473 L3.0001,17.7193 C3.0001,18.0743 3.1881,18.4023 3.4941,18.5823 C7.3101,20.8183 11.6341,21.9993 16.0001,21.9993 C20.3661,21.9993 24.6901,20.8183 28.5061,18.5823 C28.8121,18.4023 29.0001,18.0743 29.0001,17.7193 L29.0001,10.3673 L31.4611,9.0893 C31.7971,8.9143 32.0061,8.5653 32.0001,8.1863 C31.9941,7.8073 31.7741,7.4643 31.4341,7.3003 L31.4341,7.3003 Z"
              fill="var(--main-color)"
            />
            <path
              d="M25.0001,22.2984 L25.0001,11.9994 C25.0001,11.6404 24.8081,11.3094 24.4961,11.1314 L17.4961,7.1314 C17.0161,6.8574 16.4051,7.0234 16.1321,7.5034 C15.8571,7.9834 16.0241,8.5934 16.5041,8.8674 L23.0001,12.5794 L23.0001,22.2984 C21.8391,22.7124 21.0001,23.8124 21.0001,25.1144 L21.0001,28.9994 C21.0001,29.5514 21.4471,29.9994 22.0001,29.9994 C22.5531,29.9994 23.0001,29.5514 23.0001,28.9994 L23.0001,30.9994 C23.0001,31.5514 23.4471,31.9994 24.0001,31.9994 C24.5531,31.9994 25.0001,31.5514 25.0001,30.9994 L25.0001,28.9994 C25.0001,29.5514 25.4471,29.9994 26.0001,29.9994 C26.5531,29.9994 27.0001,29.5514 27.0001,28.9994 L27.0001,25.1144 C27.0001,23.8124 26.1611,22.7124 25.0001,22.2984"
              fill="var(--secondary-color)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgEducation;
