interface ObjectType {
  [key: string]: any;
}

// eslint-disable-next-line import/prefer-default-export
export function compact(obj: ObjectType) {
  return Object.keys(obj).reduce(
    (acc, key) =>
      !obj[key]
        ? acc
        : {
            ...acc,
            [key]: obj[key],
          },
    {},
  );
}
