import { TypeOf, brand, Branded, Array, Decoder } from 'io-ts';
import { isLeft } from 'fp-ts/lib/Either';
import schemaErrorReporter from 'io-ts-reporters';
import config from '../config';

export function isTypeOf<T>(input: any, validator: Decoder<any, T>): boolean {
  const validation = validator.decode(input);
  return !isLeft(validation);
}

export function assertType<T>(input: any, validator: Decoder<any, T>): asserts input is T {
  const validation = validator.decode(input);
  if (isLeft(validation)) {
    // Don't throw error if this is a prod env, the client should continue as if everything's ok
    if (config.VIM_CLIENT_LOGGER) {
      throw new Error(schemaErrorReporter.report(validation).join('\n'));
    }
  }
}

interface EmptyArrayBrand {
  readonly EmptyArray: unique symbol;
}
export const EmptyArrayV = brand(
  Array,
  (arr: Array<any>): arr is Branded<Array<any>, EmptyArrayBrand> => arr.length === 0,
  'EmptyArray',
);

export type EmptyArray = TypeOf<typeof EmptyArrayV>;
