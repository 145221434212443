import { UNAUTHORIZED } from 'http-status-codes';
import ApiError, { isApiError } from './ApiError';
import * as logger from '../utils/logger';

const authHandler = (error: Error) => {
  if (isApiError(error)) {
    if ((error as ApiError).status === UNAUTHORIZED) {
      window.location.assign('/sessionexpired');
    }
  }

  if (process.env.NODE_ENV === 'production') {
    logger.error(error);
  } else {
    throw error;
  }
};

export default authHandler;
