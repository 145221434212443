import React from 'react';
import { useTranslation } from '@getvim/translate';
import { SvgShieldCheck } from '../../atoms';
import { ButtonColor } from '../../atoms/button';
import messages from './messages';
import es from './translates/es.json';

import './index.less';
import NetworkTierDescription, { Placement } from '../networkTierDescription';

export enum NetworkStatusEnum {
  IN_NETWORK = 'IN_NETWORK',
  OUT_OF_NEWTORK = 'OUT_OF_NEWTORK',
  UNVERIFIED_NETWORK = 'UNVERIFIED_NETWORK',
}

type TierMetadata = {
  name: string;
  title: string;
  description: string;
  showInNetwork: boolean;
  overrideColor?: ButtonColor;
  shouldShowStatus?: boolean;
};

export type AsoMetadata = {
  defaultTier: string;
  tiers: TierMetadata[];
};

type InNetworkProps = {
  asoMetadata?: AsoMetadata;
  tierName?: string;
  block?: boolean;
  popoverPlacement?: Placement;
  onClick?: (e: any) => void;
};

type InNetworkComponent = React.FunctionComponent<InNetworkProps>;
const InNetwork: InNetworkComponent = ({
  tierName,
  asoMetadata,
  block = false,
  popoverPlacement = 'right',
  onClick,
}) => {
  const intl = useTranslation({ es });
  const providerTier = tierName || asoMetadata?.defaultTier;
  const foundTier = asoMetadata?.tiers.find(
    (metaDataTier) => metaDataTier.name.toUpperCase() === providerTier?.toUpperCase(),
  );
  const showInNetwork = foundTier ? foundTier.showInNetwork : true;
  const clickHandler = (e: any) => {
    if (onClick) onClick(e);
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div onClick={clickHandler}>
      {showInNetwork ? (
        <div className="in-network inline-block">
          <SvgShieldCheck />
          {intl.formatMessage(messages.inNetwork)}
        </div>
      ) : null}
      {foundTier ? (
        <NetworkTierDescription
          color={foundTier.overrideColor}
          description={foundTier.description}
          title={foundTier.title}
          block={block}
          paddingLeft={showInNetwork && !block}
          placement={popoverPlacement}
        />
      ) : null}
    </div>
  );
};

export function Unverified() {
  return (
    <div className="network-unverified">
      Network
      <br />
      unverified
    </div>
  );
}

function OutOfNetwork() {
  return <div className="out-of-network">Out-of-Network</div>;
}

const NetworkStatusToComponent = {
  [NetworkStatusEnum.UNVERIFIED_NETWORK]: Unverified,
  [NetworkStatusEnum.IN_NETWORK]: InNetwork,
  [NetworkStatusEnum.OUT_OF_NEWTORK]: OutOfNetwork,
};

type NetworkStatusChipProps = {
  networkStatus: keyof typeof NetworkStatusEnum;
  networkStatusTier?: string;
  block?: boolean;
  asoMetadata?: AsoMetadata;
  popoverPlacement?: Placement;
  hideUnverified?: boolean;
  onClick?: (e: any) => void;
};
type ComponentProps = React.FunctionComponent<NetworkStatusChipProps>;
const PureNetworkStatus: ComponentProps = ({
  networkStatus,
  networkStatusTier,
  block = false,
  asoMetadata,
  popoverPlacement,
  hideUnverified = false,

  onClick,
}) => {
  if (networkStatus === NetworkStatusEnum.UNVERIFIED_NETWORK && hideUnverified) return <div />;
  const StatusComponent = NetworkStatusToComponent[networkStatus];

  return (
    <StatusComponent
      tierName={networkStatusTier}
      block={block}
      asoMetadata={asoMetadata}
      popoverPlacement={popoverPlacement}
      onClick={onClick}
    />
  );
};

export default PureNetworkStatus;
