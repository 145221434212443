import React, { FunctionComponent } from 'react';
import { SvgBoardCertification } from '../../../atoms';
import { ProfessionalSection } from '../template';
import genderConverter from '../genderConverter';

type Props = {
  boardCertifications?: 'true' | 'false' | null;
  gender?: 'MALE' | 'FEMALE' | 'UNSPECIFIED' | null;
};

const CertificationsSection: FunctionComponent<Props> = ({ boardCertifications, gender }) => {
  const isCertified = boardCertifications === 'true';
  const certifiedMessage = !isCertified ? null : 'Board Certified';
  return (
    <ProfessionalSection
      data={certifiedMessage}
      icon={<SvgBoardCertification />}
      message={genderConverter(gender, "He's", "She's", "They're")}
    />
  );
};

export default CertificationsSection;
