import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Loader } from '@getvim/atomic-ui';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';
import useQueryString from '@getvim/components-hooks-use-query-string';
import { AnalyticsInstance } from '@getvim/components-utils-analytics';
import Formatter from '@getvim/components-utils-formatter';
import merge from 'ts-deepmerge';
import { SearchParams as SearchParamsType } from '../../api/requestTypes';
import SearchEvents from '../../utils/events/searchEvents';
import { validateParams } from './formDef';
import SearchAndAction from './SearchAndAction';
import * as Api from '../../api/api';
import { ApplicationConfig } from '../../api/responseTypes';
import authHandler from '../../api/authHandler';
import { isTypeOf } from '../../models/utils';
import { GeoTypeV } from '../../models/Geo';
import { getAccessToken, getApiKey, setAccessToken, setApiKey } from '../../api/tokens';

const parseIcd = (icd?: string[] | string) => {
  if (!icd) return undefined;
  return icd;
};

const parseQuery = ({
  searchTerm,
  memberToken,
  plan,
  geo,
  icd,
  cpt,
  insurer,
  options,
  filters = {},
}: any) => {
  if (!geo) return null;
  return {
    searchTerm,
    memberToken,
    plan,
    insurer,
    geo: {
      ...geo,
      geocode: geo.geocode
        ? {
            latitude: parseFloat(geo.geocode.latitude),
            longitude: parseFloat(geo.geocode.longitude),
          }
        : undefined,
    },
    icd: Formatter.formatQsParamToArray(icd),
    cpt: Formatter.formatQsParamToArray(cpt),
    filters: {
      ...filters,
      taxonomy: Formatter.formatQsParamToArray(filters.taxonomy),
      distance: filters.distance && parseInt(filters.distance, 10),
      onlyBookableProviders: filters.onlyBookableProviders === 'true',
      onlyInNetwork: filters.onlyInNetwork === 'true',
    },
  };
};

function generateMemberSessionId(searchParams: SearchParamsType) {
  if (searchParams?.options?.memberSessionId) return searchParams?.options?.memberSessionId;
  if (!searchParams?.memberToken) return undefined;
  return uuid();
}

function enrichWithAnalyticsTokens(searchParams: SearchParamsType, analytics: AnalyticsInstance) {
  const memberSessionId = generateMemberSessionId(searchParams);
  if (memberSessionId) {
    const { eventName, params } = SearchEvents.startMemberSession({ memberSessionId });
    analytics.track(eventName, params);
  }
  return {
    ...searchParams,
    queryId: validateParams(searchParams) ? uuid() : undefined,
    memberSessionId,
    insurer: searchParams.insurer || '',
  };
}

const defaultApplicationConfig: ApplicationConfig = {
  form: {
    televisitFilter: {
      show: false,
    },
    clinicalMatchInput: {
      show: true,
    },
    onlyInNetworkFilter: {
      show: true,
      defaultValue: false,
    },
  },
};

function getFilters(
  applicationConfig: ApplicationConfig,
  paramsFromSdk?: Partial<SearchParamsType>,
  paramsFromQuery?: Partial<SearchParamsType>,
) {
  const params = paramsFromSdk || paramsFromQuery;
  const defaultFiltersFromConfig: Partial<SearchParamsType['filters']> = {
    onlyInNetwork: applicationConfig.form?.onlyInNetworkFilter?.defaultValue,
  };
  const userFiltersWithDefaults: Partial<SearchParamsType['filters']> = {
    ...defaultFiltersFromConfig,
    ...params?.filters,
  };
  const filterValuesUserCantOverride: Partial<SearchParamsType['filters']> = {
    onlyInNetwork:
      applicationConfig.form?.onlyInNetworkFilter?.show === false
        ? defaultFiltersFromConfig.onlyInNetwork
        : userFiltersWithDefaults.onlyInNetwork,
  };
  return {
    ...userFiltersWithDefaults,
    ...filterValuesUserCantOverride,
  };
}

const SearchAndActionWrapper: FunctionComponent<{
  analytics: AnalyticsInstance;
  isGoogleApiLoaded: boolean;
}> = ({ analytics, isGoogleApiLoaded }) => {
  const [applicationConfig, setApplicationConfig] = useState<ApplicationConfig | undefined>();
  const [paramsFromQuery, setParamsFromQuery] = useState<Partial<SearchParamsType>>();
  const [paramsFromSdk, setParamsFromSdk] = useState<Partial<SearchParamsType>>();

  const searchParams = useMemo<SearchParamsType | undefined>(() => {
    let params = paramsFromSdk || paramsFromQuery;

    if (applicationConfig && params) {
      params = {
        ...params,
        displaySearchButton: paramsFromSdk?.displaySearchButton,
        filters: getFilters(applicationConfig, paramsFromSdk, paramsFromQuery),
        icd: parseIcd(params.icd),
      };
      if (!isTypeOf(params.geo, GeoTypeV)) {
        delete params.geo;
      }
    }

    return params as SearchParamsType | undefined;
  }, [paramsFromSdk, paramsFromQuery, applicationConfig]);
  const clearParams = useCallback(() => {
    setParamsFromQuery(undefined);
    setParamsFromSdk(undefined);
  }, []);
  const query = useQueryString();
  const insurer = searchParams?.insurer;

  useEffect(() => {
    const queryParams = parseQuery(query);
    if (queryParams) setParamsFromQuery(enrichWithAnalyticsTokens(queryParams, analytics));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useSdkEvents((payload) => {
    return setParamsFromSdk(enrichWithAnalyticsTokens(payload, analytics));
  });

  const apiKey =
    paramsFromSdk?.options?.apiKey || paramsFromSdk?.apiKey || query.apiKey || getApiKey();
  const accessToken = paramsFromSdk?.accessToken || query.accessToken || getAccessToken();
  setApiKey(apiKey as string);
  setAccessToken(accessToken as string);

  useEffect(() => {
    if (insurer || insurer === '')
      Api.applicationConfig(insurer || undefined)
        .then((config) => {
          const configWithDefaults = merge(defaultApplicationConfig, config);
          setApplicationConfig(configWithDefaults);
        })
        .catch((error) => {
          authHandler(error);
          setApplicationConfig(defaultApplicationConfig);
        });
  }, [insurer]);

  return applicationConfig ? (
    <SearchAndAction
      analytics={analytics}
      isGoogleApiLoaded={isGoogleApiLoaded}
      initialSearchParams={searchParams!}
      clearInitialSearchParams={clearParams}
      applicationConfig={applicationConfig!}
    />
  ) : (
    <Loader type="dots" className="margin-top-50" ariaLabel="Loading application configuration" />
  );
};

export default SearchAndActionWrapper;
