import { number, type, TypeOf } from 'io-ts';

export const GeoCodeTypeV = type(
  {
    latitude: number,
    longitude: number,
  },
  'GeoCodeType',
);

export type GeoCodeType = TypeOf<typeof GeoCodeTypeV>;
