import * as qs from 'qs';
import { Decoder } from 'io-ts';
import { deprecated } from '@getvim/atomic-ui';
import { decode } from '../utils/io-ts/io-ts-promise';
import * as logger from '../utils/logger';
import config from '../config';
import {
  ApplicationConfigV,
  ActionAnalyticsResponseV,
  AsoMetadataResponseV,
  FindResponseV,
  FreeTextResponseV,
  GetByNpiResponseV,
  ProviderAvailabilityResponseV,
  ShareProviderByPhoneResponseV,
  TaxonomiesResponseV,
} from './responseTypes';
import { GeoCodeType } from '../models/GeoCode';
import { FreeTextRequest, FindRequest, GetByNpiRequest } from './requestTypes';
import ApiError from './ApiError';
import { getAccessToken, getApiKey } from './tokens';

const defaultHeaders = (): HeadersInit => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getAccessToken()}`,
  'x-api-key': `${getApiKey()}`,
});

const { VIM_PUBLIC_API_URL: apiGWUrl } = config;

const validateResponse = <Input, Output>(validator: Decoder<Input, Output>) => async (
  response: Input,
) => {
  try {
    return await decode(validator, response);
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      logger.error('Request failed due to invalid data.', error);
      throw error;
    }
    // Conversion to Output for production purposes (needs to convert to unknown first)
    return (response as unknown) as Output;
  }
};

const validateResponseStatus = () => async (response: Response) => {
  if (!response.ok) {
    throw new ApiError(response.status, response.statusText);
  }
  return response;
};

function get<Input, Output>(
  url: string,
  validator: Decoder<Input, Output>,
  query: Record<string, unknown> = {},
) {
  const stringifiedQS = qs.stringify(
    {
      ...query,
      DCTS: Date.now(),
    },
    { skipNulls: true },
  );
  const fullUrl = `${apiGWUrl}/${url}?${stringifiedQS}`;
  return fetch(fullUrl, {
    headers: defaultHeaders(),
  })
    .then(validateResponseStatus())
    .then((response) => response.json())
    .then(validateResponse(validator));
}

function post<Input, Output>(
  url: string,
  validator: Decoder<Input, Output>,
  data: Record<string, unknown>,
) {
  const fullUrl = `${apiGWUrl}/${url}`;
  return fetch(fullUrl, {
    method: 'POST',
    headers: defaultHeaders(),
    body: JSON.stringify(data),
  })
    .then(validateResponseStatus())
    .then((response) => response.json())
    .then(validateResponse(validator));
}

export const getAllTaxonomies = (insurer?: string | null) => {
  return get('provider/taxonomies', TaxonomiesResponseV, {
    type: 'nucc',
    insurer: insurer || undefined,
  });
};

export const getProviderAvailability = (
  npi: string,
  address: string,
  geo: GeoCodeType,
  insurer?: string,
  memberToken?: string,
) => {
  return post('provider/availability', ProviderAvailabilityResponseV, {
    providers: [{ npi, address, geo }],
    insurer,
    memberToken: memberToken || undefined,
  });
};

export const find = ({
  queryId,
  memberSessionId,
  filters: { gender, spokenLanguage, taxonomy, npiList, ...filters },
  memberToken,
  geo,
  insurer,
  icd,
  cpt,
  plan,
  bcbsMemberPrefix,
  limit,
  skip,
}: FindRequest) => {
  return post('provider', FindResponseV, {
    queryId,
    memberSessionId,
    filters: {
      ...filters,
      npiList: npiList || undefined,
      taxonomy: taxonomy || undefined,
      gender: gender === 'ANY' ? undefined : gender,
      spokenLanguage: spokenLanguage === 'ANY' ? undefined : spokenLanguage,
    },
    memberToken,
    geo,
    insurer,
    icd: icd || undefined,
    cpt: cpt?.[0] || undefined,
    plan,
    bcbsMemberPrefix,
    limit,
    skip,
  }).then((response) => response.data);
};

// eslint-disable-next-line no-shadow
export function freeText({
  freeText: freeTextTerm,
  memberToken,
  filters,
  geo,
  types,
  insurer,
}: FreeTextRequest) {
  const { gender, spokenLanguage, onlyInNetwork, distance, onlyBookableProviders, state } =
    filters || {};
  return post('provider/freeText', FreeTextResponseV, {
    freeText: freeTextTerm,
    memberToken,
    filters: filters && {
      gender: gender === 'ANY' ? undefined : gender,
      spokenLanguage: spokenLanguage === 'ANY' ? undefined : spokenLanguage,
      distance,
      state,
      onlyInNetwork,
      onlyBookableProviders,
    },
    geo,
    types,
    insurer: insurer || undefined,
  });
}

export function getProviderByNpi({ npi, memberToken, geo, insurer }: GetByNpiRequest) {
  return get(`provider/${npi}`, GetByNpiResponseV, {
    memberToken,
    geo,
    insurer: insurer || undefined,
  });
}

export const shareProviderByPhone = ({
  npi,
  locationId,
  phoneNumber,
  memberToken,
  insurer,
}: {
  npi: string;
  locationId: number;
  phoneNumber: deprecated.PhoneNumberValue;
  memberToken: string;
  referringClinicName?: string;
  insurer?: string | null;
}) => {
  return post('provider/share', ShareProviderByPhoneResponseV, {
    npi,
    locationId,
    phoneNumber,
    memberToken,
    insurer: insurer || undefined,
  }).then((response) => response.data);
};

export function searchActionAnalytics(
  queryId: string,
  actionType: string,
  npi: string,
  locationId: number,
  memberSessionId?: string | null,
  ranking?: number,
  entityId?: string,
): void {
  // don't return the promise, we shouldn't wait for analytics to occur (similar to segment)
  post('search/actionAnalytics', ActionAnalyticsResponseV, {
    queryId,
    memberSessionId,
    actionType,
    npi,
    locationId,
    ranking,
    entityId,
  })
    .then(() => ({ success: true }))
    .catch(() => ({ success: false }));
}

export const asoMetadata = (memberToken: string, insurer?: string) => {
  return post('asoMetadata', AsoMetadataResponseV, {
    memberToken,
    insurer: insurer || undefined,
  }).then((response) => response.data);
};

export const applicationConfig = (insurer?: string) => {
  return get('applicationConfig/searchSdk', ApplicationConfigV, { insurer: insurer || undefined });
};
