import React, { FunctionComponent } from 'react';
import { useTranslation } from '@getvim/translate';
import { SvgEducation, Text } from '../../../atoms';
import { ColorNameEnum } from '../../../types';
import { ProfessionalSection } from '../template';
import genderConverter from '../genderConverter';
import es from '../translates/es.json';
import messages from '../messages';

type Props = {
  medicalDegree?: string | null;
  gender?: 'MALE' | 'FEMALE' | 'UNSPECIFIED' | null;

  application?: string;
};

const EducationSection: FunctionComponent<Props> = ({ medicalDegree, gender, application }) => {
  const intl = useTranslation({ es });
  const message = genderConverter(
    gender,
    intl.formatMessage(messages.heGotMedicalDegree),
    intl.formatMessage(messages.sheGotMedicalDegree),
    intl.formatMessage(messages.theyGotMedicalDegree),
  );

  return (
    <ProfessionalSection
      data={medicalDegree}
      icon={
        application === 'premera' ? (
          <SvgEducation />
        ) : (
          <Text className="icon-award" colorName={ColorNameEnum.themeSecondary} />
        )
      }
      message={message}
    />
  );
};

export default EducationSection;
