let accessToken: string;
export function setAccessToken(token: string) {
  accessToken = token;
}
export function getAccessToken() {
  return accessToken;
}

let apiKey: string;
export function setApiKey(key: string) {
  apiKey = key;
}
export function getApiKey() {
  return apiKey;
}
