/**
 * @extends Error
 */
class ApiError extends Error {
  status?: number;

  statusText?: string;

  constructor(status?: number, statusText?: string) {
    super(statusText);
    this.name = this.constructor.name;
    this.status = status;
    this.statusText = statusText;
  }
}

export function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}

export default ApiError;
