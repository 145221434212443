import React from 'react';
import { ThemePropsType } from '@getvim/components-hooks-use-theme';
import { Button, AsoMetadata } from '@getvim/atomic-ui';
import { AnalyticsInstance } from '@getvim/components-utils-analytics';
import BkmdModalButton from '../ModalButton/ModalButton';
import DoctorDetails from './Modal/ProviderDetailsModal';
import { ProviderType } from '../../models/Provider';
import { LocationType } from '../../models/Location';
import { copyToClipboard, prepareProviderDataToCopy } from '../../utils/copyToClipboard';

import './DetailsButton.less';

const DetailsButton = ({
  provider,
  setSelectedProvider,
  displayCopyButton,
  theme,
  insurer,
  onDetailsModalShow,
  onDetailsModalClose,
  onSelectProvider,
  asoMetadata,
  analytics,
}: {
  provider: ProviderType;
  setSelectedProvider: (providerLocation: {
    provider: ProviderType;
    location: LocationType;
  }) => void;
  displayCopyButton: boolean;
  theme: ThemePropsType;
  insurer?: string | null;
  onDetailsModalShow?: () => void;
  onDetailsModalClose?: () => void;
  onSelectProvider: () => void;
  asoMetadata?: AsoMetadata;
  analytics?: AnalyticsInstance;
}) => {
  return (
    <BkmdModalButton
      title="Details"
      buttonType="small"
      bgColor="themedOutline"
      onModalShow={onDetailsModalShow}
      onModalClose={onDetailsModalClose}
      Component={({ closeModal }) => (
        <DoctorDetails
          provider={provider}
          asoMetadata={asoMetadata}
          insurer={insurer}
          selectedLocation={provider.locations[0]}
          theme={theme}
          analytics={analytics}
          renderActions={(currProvider, currLocation) => (
            <div className="row small-gutter">
              <div className="col-xs-6">
                <Button
                  onClick={closeModal}
                  className="pull-right"
                  bgColor="themedOutline"
                  width="block"
                  buttonType="small"
                >
                  Close
                </Button>
              </div>
              <div className="col-xs-6">
                <Button
                  onClick={() => {
                    if (displayCopyButton) {
                      const copyData = prepareProviderDataToCopy(currProvider);
                      copyToClipboard(copyData);
                    }
                    onSelectProvider();
                    closeModal();
                    if (currLocation && currProvider) {
                      setSelectedProvider({ provider: currProvider, location: currLocation });
                    }
                  }}
                  text={displayCopyButton ? 'Copy Details' : 'Select'}
                  className="pull-left"
                  width="block"
                  buttonType="small"
                />
              </div>
            </div>
          )}
        />
      )}
      modalClassName="fullscreen-modal no-padding-modal"
    />
  );
};

export default DetailsButton;
