import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.less';

const GrayBgTemplateProps = {
  topSectionContent: PropTypes.element,
  bottomSectionContent: PropTypes.element,
  topSectionClass: PropTypes.string,
  bottomSectionClass: PropTypes.string,
};

type GrayBgTemplatePropsType = PropTypes.InferProps<typeof GrayBgTemplateProps>;

const GrayBgTemplate: FunctionComponent<GrayBgTemplatePropsType> = ({
  topSectionContent,
  bottomSectionContent,
  topSectionClass,
  bottomSectionClass,
}) => (
  <div className="gray-bg-template-wrap">
    <div className={classNames('template-top-section', topSectionClass)}>{topSectionContent}</div>
    <div className={classNames('template-bottom-section', bottomSectionClass)}>
      {bottomSectionContent}
    </div>
  </div>
);

GrayBgTemplate.propTypes = GrayBgTemplateProps;

GrayBgTemplate.defaultProps = {
  topSectionContent: undefined,
  bottomSectionContent: undefined,
  topSectionClass: undefined,
  bottomSectionClass: undefined,
};

export default GrayBgTemplate;
