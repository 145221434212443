import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Formatter from '@getvim/components-utils-formatter';
import { ClickableDiv, Text } from '../../atoms';
import { NetworkStatusChip, NetworkStatusEnum, AsoMetadata } from '../../molecules';
import { AlignEnum, ColorNameEnum, SizeEnum, WeightEnum } from '../../types';
import { ProviderLogo } from '../providerLogo';

import './MiniProviderCard.less';

export interface MiniProviderCardProps {
  onClick?: (payload?: any) => void;
  className: string;
  localPhoneFormat?: string | null;
  provider: {
    title?: string | null;
    firstName: string;
    middleName?: string | null;
    lastName: string;
    suffix?: string | null;
    taxonomies: {
      subSpecialtyDescription?: string | null;
    }[];
  };
  location: {
    phoneNumber?: {
      number: string;
      countryDialingCode: string;
    } | null;
    network?: {
      status: NetworkStatusEnum;
      tier?: string;
    };
    implementationSpecificFields?: {
      telehealth?: string | null;
      webpage?: string | null;
    } | null;
  };
  renderActions: any;
  asoMetadata?: AsoMetadata;
  isHighValueBadgeEnable?: boolean;
  showShadow?: boolean;
  resultMainIcon?: JSX.Element;
  resultTopBadges?: JSX.Element;
  resultBottomBadges?: JSX.Element;
  showPhone?: boolean;
  formattedTaxonomies?: any;
}

const Mini: FunctionComponent<MiniProviderCardProps> = ({
  onClick,
  className,
  provider,
  location,
  renderActions,
  asoMetadata,
  isHighValueBadgeEnable,
  showShadow,
  resultMainIcon,
  resultTopBadges,
  localPhoneFormat,
  resultBottomBadges,
  showPhone = true,
  formattedTaxonomies = undefined,
}) => {
  const taxonomies = Formatter.formatTaxonomies(provider.taxonomies);
  const fullProviderName = Formatter.formatProviderTitle(provider);
  return (
    <ClickableDiv
      disabled={!onClick}
      clickHandler={onClick}
      className={classNames(className, 'card mini-doctor-card rounded-card', {
        'no-shadow': !showShadow,
      })}
    >
      <div className="card-body">
        <div className="card-left">
          <div className="card-img">
            <ProviderLogo provider={provider} isHighValueBadgeEnable={isHighValueBadgeEnable} />
          </div>
          <div className="network-status">
            {location.network && (
              <NetworkStatusChip
                networkStatus={NetworkStatusEnum[location.network.status]}
                networkStatusTier={location.network.tier}
                asoMetadata={asoMetadata}
                hideUnverified
                popoverPlacement="right"
              />
            )}
          </div>
        </div>
        <div className="doctor-info">
          <Text
            align={AlignEnum.left}
            weight={WeightEnum.bold}
            colorName={ColorNameEnum.ultraDark}
            size={SizeEnum['18px']}
            className="main-text margin-top-10"
          >
            {fullProviderName}
            {location.implementationSpecificFields?.telehealth ? (
              <i
                className="icon-video-cam telehealth-icon"
                style={{ color: 'var(--accent-color)' }}
              />
            ) : null}
            {resultTopBadges}
            {resultMainIcon}
          </Text>
          <Text
            colorName={ColorNameEnum.ultraDark}
            align={AlignEnum.left}
            weight={WeightEnum.bold}
            className="margin-top-5"
          >
            {formattedTaxonomies || taxonomies}
          </Text>
          {showPhone && location.phoneNumber && (
            <Text
              colorName={ColorNameEnum.ultraDark}
              align={AlignEnum.left}
              weight={WeightEnum.medium}
              className="margin-top-5"
            >
              {localPhoneFormat || Formatter.formatPhoneToLocal(location.phoneNumber)}
            </Text>
          )}
        </div>
      </div>
      {resultBottomBadges && <div className="bottom-badges">{resultBottomBadges}</div>}
      {renderActions && <div className="card-actions">{renderActions(provider, location)}</div>}
    </ClickableDiv>
  );
};

export default Mini;
