import React, { FunctionComponent } from 'react';

const SvgStethoscope: FunctionComponent = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    className="svg-icon svg-stethoscope"
    width="23px"
    height="32px"
    viewBox="0 0 23 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-312.000000, -295.000000)">
        <g transform="translate(312.000000, 295.000000)">
          <path
            d="M10,4 C9.447,4 9,3.552 9,3 L9,1 C9,0.448 9.447,0 10,0 C10.553,0 11,0.448 11,1 L11,3 C11,3.552 10.553,4 10,4"
            fill="var(--secondary-color)"
          />
          <path
            d="M20,4 C19.447,4 19,3.552 19,3 L19,1 C19,0.448 19.447,0 20,0 C20.553,0 21,0.448 21,1 L21,3 C21,3.552 20.553,4 20,4"
            fill="var(--secondary-color)"
          />
          <path
            d="M2,21.5 C2,20.397 2.897,19.5 4,19.5 C5.103,19.5 6,20.397 6,21.5 C6,22.603 5.103,23.5 4,23.5 C2.897,23.5 2,22.603 2,21.5 M22,0 C21.447,0 21,0.448 21,1 L21,10 C21,13.309 18.309,16 15,16 C11.691,16 9,13.309 9,10 L9,1 C9,0.448 8.553,0 8,0 C7.447,0 7,0.448 7,1 L7,10 C7,14.411 10.589,18 15,18 L15,25 C15,27.757 12.757,30 10,30 C7.362,30 5.217,27.939 5.035,25.347 C6.737,24.888 8,23.345 8,21.5 C8,19.294 6.206,17.5 4,17.5 C1.794,17.5 0,19.294 0,21.5 C0,23.365 1.288,24.923 3.019,25.364 C3.21,29.054 6.264,32 10,32 C13.859,32 17,28.86 17,25 L17,18 C17,17.911 16.972,17.832 16.949,17.75 C20.42,16.876 23,13.738 23,10 L23,1 C23,0.448 22.553,0 22,0"
            fill="var(--main-color)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgStethoscope;
