import React, { FunctionComponent, useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { InputStyleWrapper, InputStyle } from '../../atoms';

type InputType = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

export interface PhoneNumberValue {
  number: string;
  countryDialingCode?: string;
}

export interface PhoneNumberInputProps {
  initialValue: PhoneNumberValue;
  onChange: (phoneNumber: PhoneNumberValue) => void;

  label?: boolean | string;
}

export const valuePhoneNumber = (number: string) => {
  const phoneNumber = parsePhoneNumberFromString(number, 'US');
  return phoneNumber?.formatNational();
};

const formatPhoneNumber = (number: string) => {
  const phoneNumber = parsePhoneNumberFromString(number, 'US');
  return {
    number: (phoneNumber?.nationalNumber as string) || '',
    countryDialingCode: phoneNumber?.countryCallingCode as string,
  };
};

const PhoneNumberInput: FunctionComponent<PhoneNumberInputProps & InputType> = ({
  initialValue,
  onChange,
  label,
  ...other
}) => {
  const [hasValue, setHasValue] = useState<boolean>(!!initialValue.number);

  return (
    <InputStyleWrapper inputStyle={InputStyle.clean}>
      <input
        type="tel"
        defaultValue={valuePhoneNumber(initialValue.number) || ''}
        onChange={(event) => {
          setHasValue(!!event.target.value.length);
          onChange(formatPhoneNumber(event.target.value.replace(/\D/g, '')));
        }}
        placeholder={`${!label ? 'Phone number' : ''}`}
        {...other}
      />
      {label && (
        <label className={` ${hasValue ? 'input-active' : 'static-label-header'}`}>{label}</label>
      )}
    </InputStyleWrapper>
  );
};

PhoneNumberInput.defaultProps = {
  label: false,
};
export default PhoneNumberInput;
