import { deprecated } from '@getvim/atomic-ui';
import { GeoType, GeoTypeV } from '../../models/Geo';
import { isTypeOf } from '../../models/utils';
import { FreeTextOption } from '../../api/requestTypes';

export declare type BarFiltersType = {
  distance: number;
  gender: deprecated.GenderEnum;
  spokenLanguage: deprecated.LangEnum;
  onlyInNetwork: boolean;
  onlyBookableProviders: boolean;
};

export type AnalyticableFilters = BarFiltersType & { geo: GeoType | null };

export type FormFiltersValues = BarFiltersType & {
  taxonomy: string[] | null;
  npiList: string[] | null;
};

export type FormDefTypes = {
  icd: FreeTextOption | null;
  cpt: FreeTextOption | null;
  memberToken?: string;
  queryId?: string;
  memberSessionId?: string | null;
  plan?: string;
  geo?: GeoType | null;
  filters: FormFiltersValues;
};

export const defaultFilterValues: BarFiltersType = {
  gender: 'ANY',
  distance: 10,
  spokenLanguage: 'ANY',
  onlyInNetwork: false,
  onlyBookableProviders: false,
};

export const defaultFormValues: FormDefTypes = {
  icd: null,
  cpt: null,
  filters: {
    ...defaultFilterValues,
    taxonomy: null,
    npiList: null,
  },
};

export const validateParams = (params: { filters?: any; geo?: GeoType | null } = {}) => {
  const { filters, geo } = params;
  const { taxonomy, npiList } = filters || {};
  // Params here sometimes come from parent iframe or from query params, so we check them strictly
  return !!((taxonomy || npiList) && isTypeOf(geo, GeoTypeV));
};
