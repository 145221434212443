import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import classNames from 'classnames';
import { Text, ColorNameEnum, SizeEnum } from '@getvim/atomic-ui';
import { defineMessages } from 'react-intl';
import { useTranslation } from '@getvim/translate';
import es from './translations/es.json';
import './highValueBadge.less';

const messages = defineMessages({
  highValueProvider: {
    defaultMessage: 'High Value Provider',
    id: 'highValueBadge.highValueProvider',
  },
});

const HighValueBadgeProps = {
  className: PropTypes.string,
};

type HighValueBadgePropsType = InferProps<typeof HighValueBadgeProps>;

const HighValueBadge: FunctionComponent<HighValueBadgePropsType> = ({ className }) => {
  const intl = useTranslation({ es });
  return (
    <span className={classNames('high-value-badge', className)}>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="high-value" className="high-value-tooltip">
            {intl.formatMessage(messages.highValueProvider)}
          </Tooltip>
        }
      >
        <Text
          inline
          colorName={ColorNameEnum.ultraDark}
          size={SizeEnum['18px']}
          className="icon-award-2"
        />
      </OverlayTrigger>
    </span>
  );
};

HighValueBadge.propTypes = HighValueBadgeProps;
HighValueBadge.defaultProps = {
  className: '',
};

export default HighValueBadge;
