import { ProviderType } from '../../models/Provider';
import { PhoneNumberType } from '../../models/PhoneNumber';
import { TaxonomyType } from '../../models/Taxonomy';

const suffixesThatShouldntBeUppercase = [
  { original: 'MeD', inUppercase: 'MED' },
  { original: 'PharmD', inUppercase: 'PHARMD' },
  { original: 'EdD', inUppercase: 'EDD' },
  { original: 'AuD', inUppercase: 'AUD' },
  { original: 'PsyD', inUppercase: 'PSYD' },
  { original: 'PhD', inUppercase: 'PHD' },
];

function parseSuffix(rawSuffix: string) {
  const rawSuffixInUppercase = rawSuffix.toUpperCase();
  for (const { original, inUppercase } of suffixesThatShouldntBeUppercase) {
    if (rawSuffixInUppercase.match(inUppercase)) return original;
  }
  return rawSuffixInUppercase;
}

function uniqueValuesFilter<T>(currentValue: T, currentIndex: number, sourceArray: T[]) {
  return sourceArray.indexOf(currentValue) === currentIndex;
}

class Formatter {
  /**
   * Most of the time, physician titles are in uppercase (like MD). Sometimes, they're not (like
   * PhD). This function takes the title/titles from the full name and parses them in the correct
   * capitalization. Also, this function takes into account that some providers have multiple titles
   * separated by comma.
   */
  static formatProviderTitle({
    title,
    firstName,
    middleName,
    lastName,
    suffix,
  }: Pick<ProviderType, 'title' | 'firstName' | 'middleName' | 'lastName' | 'suffix'>) {
    // get suffixes in array instead of comma limited string
    const suffixes = suffix?.length
      ? suffix
          .split(',')
          .map((s) => s.trim())
          .filter((s) => s.length)
      : [];
    const parsedSuffix = suffixes.map(parseSuffix).join(', ');
    const fullProviderTitleWithoutSuffix = [title, firstName, middleName, lastName]
      .filter((s) => s?.length)
      .join(' ');
    return suffix
      ? `${fullProviderTitleWithoutSuffix}, ${parsedSuffix}`
      : fullProviderTitleWithoutSuffix;
  }

  static formatTaxonomies(taxonomies: TaxonomyType[]) {
    return taxonomies
      .map((tax) => tax.subSpecialtyDescription)
      .filter(uniqueValuesFilter)
      .join(', ')
      .trim();
  }

  static formatPhoneToLocal(phoneNumber: PhoneNumberType) {
    const cleaned = `${phoneNumber.number}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const [, areaCode, prefix, lineNumber] = match;
      return `(${areaCode}) ${prefix}-${lineNumber}`;
    }
    return phoneNumber.number;
  }

  static formatQsParamToArray(param?: any): any[] {
    if (!param) return param;
    return Array.isArray(param)
      ? param.map((tax) => String(tax).trim())
      : String(param).replace(/\s+/g, '').split(',');
  }
}

export default Formatter;
