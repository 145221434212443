import React from 'react';

export type MapSnapshotProps = {
  geo: {
    latitude: number;
    longitude: number;
  };
  width?: number;
  height?: number;
  mapConfig: {
    templateUrl: string;
    key: string;
  };
};

function fillTemplate(template: string, point: string, key: string, width: number, height: number) {
  let result = template.replace(/\*\|point\|\*/g, point);
  result = result.replace(/\*\|key\|\*/g, key);
  result = result.replace(/\*\|width\|\*/g, `${width}`);
  result = result.replace(/\*\|height\|\*/g, `${height}`);
  return result;
}

type ComponentType = React.FunctionComponent<MapSnapshotProps>;
const MapSnapshot: ComponentType = ({
  geo,
  width = 555,
  height = 160,
  mapConfig: { templateUrl, key },
}) => {
  const point = `${geo.latitude},${geo.longitude}`;
  const mapSrc = fillTemplate(templateUrl, point, key, width, height);
  return <img className="img-cover" src={mapSrc} alt="Map location" />;
};

MapSnapshot.defaultProps = {
  width: 555,
  height: 160,
};

export default MapSnapshot;
