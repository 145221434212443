import { partial, string, TypeOf, Branded, brand } from 'io-ts';
import { GeoCodeTypeV } from './GeoCode';

const BaseGeoV = partial(
  {
    geocode: GeoCodeTypeV,
    zip: string,
    address: string,
  },
  'GeoType',
);

interface GeoBrand {
  readonly Geo: unique symbol;
}
export const GeoTypeV = brand(
  BaseGeoV,
  (geo: TypeOf<typeof BaseGeoV>): geo is Branded<TypeOf<typeof BaseGeoV>, GeoBrand> => {
    return !!(
      geo.address ||
      geo.zip ||
      (geo.geocode &&
        typeof geo.geocode.latitude === 'number' &&
        typeof geo.geocode.longitude === 'number')
    );
  },
  'Geo',
);

export type GeoType = TypeOf<typeof GeoTypeV>;
