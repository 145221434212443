import * as t from 'io-ts';

export class EnumType<A> extends t.Type<A> {
  public readonly _tag: 'EnumType' = 'EnumType';

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    name: string,
    is: EnumType<A>['is'],
    validate: EnumType<A>['validate'],
    encode: EnumType<A>['encode'],
  ) {
    super(name, is, validate, encode);
  }
}

export const enumType = <A>(e: { [key: string]: A }, name = 'Enum'): EnumType<A> => {
  const is = (u: unknown): u is A => Object.keys(e).some((k) => e[k] === u);
  return new EnumType<A>(name, is, (u, c) => (is(u) ? t.success(u) : t.failure(u, c)), t.identity);
};
