import { ProviderType } from '../models/Provider';

export function copyToClipboard(text: string) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
}

export function prepareProviderDataToCopy({
  firstName,
  lastName,
  suffix,
  locations,
  taxonomies,
  npi,
}: ProviderType) {
  const { phoneNumber, fax } = locations[0];
  const providerSpecialties = taxonomies
    .map((taxonomy) => taxonomy.subSpecialtyDescription)
    .join(', ');

  let providerData = `${firstName} ${lastName}, ${suffix}\nSpecialty: ${providerSpecialties}\nNPI: ${npi}\nAddress: ${locations[0].address}`;

  if (phoneNumber) {
    providerData += `\nPhone number: ${phoneNumber.countryDialingCode}${phoneNumber.number}`;
  }
  if (fax) {
    providerData += `\nFax: ${fax.countryDialingCode}${fax.number}`;
  }

  return providerData;
}
