import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { useTranslation } from '@getvim/translate';
import { Text } from '../../atoms';
import {
  CertificationSection,
  EducationSection,
  LanguagesSection,
  SpecializesInProfessionalSection,
} from '../../molecules';
import { ColorNameEnum, SizeEnum, WeightEnum, AlignEnum } from '../../types';
import './ProfessionalBackground.less';
import messages from './messages';
import es from './translates/es.json';

type ProfessionalBackgroundType = {
  details: {
    gender?: 'MALE' | 'FEMALE' | 'UNSPECIFIED' | null;
    boardCertifications?: 'true' | 'false' | null;
    languages?: {
      id: string;
      iso3?: string;
      name: string;
      nativeName: string;
    }[];
    medicalDegree?: string | null;
    taxonomies: {
      taxonomyCode: string;
      taxonomyDisplayName?: string;
      subSpecialtyDescription?: string;
      taxonomyDescription?: string;
    }[];
  };
  className: string;
  application?: string;
  showBoardCertifications?: boolean;
  nativeLanguageLabels?: boolean;
};

const ProfessionalBackground: FunctionComponent<ProfessionalBackgroundType> = ({
  details: { gender, boardCertifications, languages, medicalDegree, taxonomies },
  className,
  application,
  showBoardCertifications = true,
  nativeLanguageLabels = false,
}) => {
  const intl = useTranslation({ es });
  return (
    <div className={classNames(className, 'professional-background-section')}>
      <Text
        align={AlignEnum.left}
        size={SizeEnum['16px']}
        colorName={ColorNameEnum.ultraDark}
        weight={WeightEnum.bold}
      >
        {intl.formatMessage(messages.professionalBackground)}
      </Text>
      {showBoardCertifications && (
        <CertificationSection gender={gender} boardCertifications={boardCertifications} />
      )}
      <SpecializesInProfessionalSection
        application={application}
        gender={gender}
        taxonomies={taxonomies.map((taxonomy) => ({
          ...taxonomy,
          subSpecialtyDescription: taxonomy.taxonomyDescription,
        }))}
      />
      <EducationSection application={application} gender={gender} medicalDegree={medicalDegree} />
      <LanguagesSection
        nativeLanguageLabels={nativeLanguageLabels}
        application={application}
        gender={gender}
        languages={languages}
      />
    </div>
  );
};

export default ProfessionalBackground;
