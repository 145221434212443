import React, { FunctionComponent, useState } from 'react';
import { Button, ButtonProps, BkmdModal, ModalHeader } from '@getvim/atomic-ui';

import './ModalButton.less';

interface ComponentProps {
  closeModal: () => void;
  showModal: () => void;
}

export type ModalButtonProps = {
  Component: React.ComponentType<ComponentProps>;
  title?: string;
  modalClassName?: string;
  modalBaseClassName?: string;
  className?: string;
  btnClassName?: string;
  showHeader?: boolean;
  onModalShow?: () => void;
  onModalClose?: () => void;
} & ButtonProps;

const ModalButton: FunctionComponent<ModalButtonProps> = ({
  title,
  Component,
  modalClassName,
  modalBaseClassName,
  className,
  btnClassName,
  showHeader,
  onModalShow = () => {},
  onModalClose = () => {},
  ...otherProps
}) => {
  const [isOn, setIsOn] = useState(false);
  const on = () => {
    onModalShow();
    setIsOn(true);
  };
  const off = () => {
    onModalClose();
    setIsOn(false);
  };
  const toggle = () => {
    if (isOn) onModalClose && onModalClose();
    else onModalShow && onModalShow();

    setIsOn(!isOn);
  };

  return (
    <span className={className}>
      <Button onClick={toggle} text={title} className={btnClassName} {...otherProps} />
      {isOn && (
        <BkmdModal
          baseClassName={modalBaseClassName}
          className={modalClassName}
          handleClose={off}
          isOpen
        >
          {showHeader && <ModalHeader title={title} onClose={off} />}
          <div className="dialog-body">
            <Component closeModal={off} showModal={on} {...otherProps} />
          </div>
        </BkmdModal>
      )}
    </span>
  );
};

export default ModalButton;
