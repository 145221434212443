import {
  array,
  boolean,
  intersection,
  nullType,
  number,
  partial,
  string,
  literal,
  type,
  TypeOf,
  union,
} from 'io-ts';
import { LocationTypeV } from './Location';
import { ClinicalMatchTypeV } from './ClinicalMatch';
import { TaxonomyTypeV } from './Taxonomy';

export const ProviderTypeV = intersection(
  [
    type({
      npi: string,
      firstName: string,
      lastName: string,
      taxonomies: array(TaxonomyTypeV),
      locations: array(LocationTypeV),
    }),
    partial({
      middleName: union([string, nullType]),
      suffix: string,
      title: union([string, nullType]),
      gender: union([literal('MALE'), literal('FEMALE'), literal('UNSPECIFIED')]),
      profilePicture: union([string, nullType]),
      acceptNewPatients: union([boolean, nullType]),
      preferredProvider: boolean,
      internalProvider: boolean,
      ranking: number,
      ratingCount: number,
      rating: union([number, nullType]),
      languages: array(
        intersection([
          type({
            id: string,
            name: string,
            nativeName: string,
          }),
          partial({
            iso3: string,
          }),
        ]),
      ),
      hvns: array(
        partial({
          domain: string,
        }),
      ),
      score: number,
      scoreByAgeByNucc: array(
        partial({
          maxAge: number,
          minAge: number,
          score: number,
          taxonomy: string,
        }),
      ),
      ageClinicalMatch: array(string),
      clinicalMatch: union([ClinicalMatchTypeV, nullType]),
      boardCertifications: union([literal('true'), literal('false'), nullType]),
      medicalDegree: union([string, nullType]),
    }),
  ],
  'ProviderType',
);

export type ProviderType = TypeOf<typeof ProviderTypeV>;
