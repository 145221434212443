import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@getvim/components-hooks-use-theme';
import { deprecated } from '@getvim/atomic-ui';
import ShareSelection from './Components/ShareSelection';
import SelectedProviderActions from './Components/SelectedProviderActions';
import { LocationType } from '../../models/Location';
import * as Api from '../../api/api';

import './ResultCardBanner.less';
import { ApplicationConfig } from '../../api/responseTypes';

export interface ResultCardBannerProps {
  npi: string;
  fullProviderName: string;
  location: LocationType;
  insurer?: string;
  memberToken?: string;
  onBook: (payload: { npi: string; address: string }) => void;
  onCancel: () => void;
  onClose: () => void;
  onShareSuccess?: (correlationId: string) => void;
  onShareClick?: () => void;
  onShareSubmitClick?: () => void;
  onShareFailed?: (error: any) => void;
  memberPhoneNumber?: string;
  applicationConfig: ApplicationConfig;
  displayCopyButton: boolean;
}

export enum Step {
  Actions = 'Actions',
  Share = 'Share',
}

const ResultCardBanner: FunctionComponent<ResultCardBannerProps> = ({
  npi,
  fullProviderName,
  location,
  insurer,
  onBook,
  onCancel,
  onClose,
  onShareSuccess = () => {},
  onShareClick = () => {},
  onShareSubmitClick = () => {},
  onShareFailed = () => {},
  memberToken,
  memberPhoneNumber,
  applicationConfig,
  displayCopyButton,
}) => {
  const theme = useTheme();
  const defaultStep = Step.Actions;
  const [step, setStep] = useState<Step>(defaultStep);
  const [isShareLoading, setIsShareLoading] = useState(false);

  const onShare = (phoneNumber: deprecated.PhoneNumberValue) => {
    if (memberToken) {
      onShareSubmitClick();
      setIsShareLoading(true);
      Api.shareProviderByPhone({ npi, locationId: location.id, phoneNumber, memberToken, insurer })
        .then(({ correlationId }) => onShareSuccess(correlationId))
        .catch((error) => onShareFailed(error))
        .then(() => setIsShareLoading(false))
        .then(() => document.body.click());
    }
  };

  return (
    <div className="result-card-banner">
      {step === Step.Actions && (
        <SelectedProviderActions
          theme={theme}
          npi={npi}
          fullProviderName={fullProviderName}
          location={location}
          memberToken={memberToken}
          displayCopyButton={displayCopyButton}
          onBook={onBook}
          onShareClick={() => {
            setStep(Step.Share);
            onShareClick();
          }}
          onClose={onClose}
          displayBookButton={applicationConfig.displayBookButton ?? true}
          displayShareButton={applicationConfig.displayShareButton ?? true}
        />
      )}
      {step === Step.Share && (
        <ShareSelection
          isLoading={isShareLoading}
          onSubmit={(phoneNumber) => {
            onShare(phoneNumber);
            setStep(Step.Actions);
          }}
          onClose={() => setStep(Step.Actions)}
          initialPhoneNumber={memberPhoneNumber}
        />
      )}
    </div>
  );
};

export default ResultCardBanner;
