import { defineMessages } from 'react-intl';

export default defineMessages({
  heSpeaks: {
    id: 'languageSection.heSpeaks',
    defaultMessage: 'He speaks',
  },
  sheSpeaks: {
    id: 'languageSection.sheSpeaks',
    defaultMessage: 'She speaks',
  },
  theySpeak: {
    id: 'languageSection.theySpeak',
    defaultMessage: 'They speak',
  },
  he: {
    id: 'languageSection.he',
    defaultMessage: 'He',
  },
  she: {
    id: 'languageSection.she',
    defaultMessage: 'She',
  },
  they: {
    id: 'languageSection.they',
    defaultMessage: 'They',
  },
  heSpecializesIn: {
    id: 'languageSection.heSpecializesIn',
    defaultMessage: 'specializes in',
  },
  sheSpecializesIn: {
    id: 'languageSection.sheSpecializesIn',
    defaultMessage: 'specializes in',
  },
  theySpecializeIn: {
    id: 'languageSection.theySpecializeIn',
    defaultMessage: 'specialize in',
  },
  heGotMedicalDegree: {
    id: 'languageSection.heGotMedicalDegree',
    defaultMessage: 'He got his medical degree at',
  },
  sheGotMedicalDegree: {
    id: 'languageSection.sheGotMedicalDegree',
    defaultMessage: 'She got her medical degree at',
  },
  theyGotMedicalDegree: {
    id: 'languageSection.theyGotMedicalDegree',
    defaultMessage: 'They got their medical degree at',
  },
});
